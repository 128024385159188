export enum DASHBOARD_TAB {
  LEAD = 'LEAD',
  POLICY = 'POLICY',
  PERFORMANCE = 'PERFORMANCE',
  USER = 'USER'
}

export enum LEAD_STATISTIC {
  TOTAL_LEADS_ALL_TIME = 'TOTAL_LEADS_ALL_TIME',
  CREATED_LEADS_ALL_TIME = 'CREATED_LEADS_ALL_TIME',
  ASSIGNED_LEADS_ALL_TIME = 'ASSIGNED_LEADS_ALL_TIME',

  TOTAL_LEADS_BY_TIME = 'TOTAL_LEADS_BY_TIME',
  CREATED_LEADS_BY_TIME = 'CREATED_LEADS_BY_TIME',
  ASSIGNED_LEADS_BY_TIME = 'ASSIGNED_LEADS_BY_TIME'
}

export enum AUDIENCE_INSIGHTS_DROPDOWN {
  AMOUNT_SPENT = 'amount_spent',
  CONVERSIONS_LEADS = 'conversion_leads',
  AVERAGE_COST = 'average_cost',
  CONVERSIONS = 'conversion_leads',
  MESSAGE_CONVERSATIONS_STARTED = 'message_conversations_started',
  COST_PER_LEAD = 'cost_per_lead',
  TOTAL_REVENUE = 'total_revenue',
  IMPRESSIONS = 'impressions',
  CLICKS_ALL = 'clicks_all',
  CTR = 'ctr',
  AVERAGE_CPC = 'average_cpc',
  COST_PER_ENGAGEMENT = 'cost_per_engagement',
  COST_PER_THRUPLAY = 'cost_per_thruplay'
}

export enum USER_STATISTIC {
  TOTAL_USERS = 'TOTAL_USERS',
  ACTIVE_USERS = 'ACTIVE_USERS',
  HIDDEN_USERS = 'HIDDEN_USERS',
  INACTIVE_USERS = 'INACTIVE_USERS'
}

export enum POLICY_STATISTIC {
  // 1
  PREMIUM = 'PREMIUM',
  POLICIES_OF_PREMIUM = 'POLICIES_OF_PREMIUM',

  // 2
  ISSUED_PREMIUM = 'ISSUED_PREMIUM',
  POLICIES_OF_ISSUED_PREMIUM = 'POLICIES_OF_ISSUED_PREMIUM',

  // 3
  PREMIUM_BY_TIME = 'PREMIUM_BY_TIME',
  POLICIES_OF_PREMIUM_BY_TIME = 'POLICIES_OF_PREMIUM_BY_TIME',

  // 4
  ISSUED_PREMIUM_BY_TIME = 'ISSUED_PREMIUM_BY_TIME',
  POLICIES_OF_ISSUED_PREMIUM_BY_TIME = 'POLICIES_OF_ISSUED_PREMIUM_BY_TIME'
}

export enum PERFORMANCE_STATISTIC {
  TOTAL_LEADS = 'TOTAL_LEADS',
  TOTAL_NOTES = 'TOTAL_NOTES',
  TOTAL_TASKS = 'TOTAL_TASKS',
  TOTAL_APPOINTMENTS = 'TOTAL_APPOINTMENTS',
  TOTAL_CLICK_CALLS = 'TOTAL_CLICK_CALLS',
  TOTAL_ONLINE_HOURS = 'TOTAL_ONLINE_HOURS'
}

export enum LeadsByUsersSortFieldEnum {
  LEAD_TAKE_CARE = 'leadTakeCare',
  CREATED_LEAD = 'createdLead',
  FULL_NAME = 'user.fullName'
}

export enum UNIT_NUMBER {
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  PERCENT = 'PERCENT'
}
