import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseApi } from '@core/models/interfaces/common';
import {
  PerformanceInsights,
  ReachEngagementInsights,
  ReachEngagementStatistical,
  StaticalPerformanceInsights
} from '@core/models/interfaces/marketing-dashboard/dashboard';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FacebookDashboardService {
  private readonly baseUrl = `${environment.API_URL}/crm/facebook-dashboards`;

  constructor(private httpClient: HttpClient) {}

  getReachEngagementStatistical(adAccountIds: string[], accessToken: string, since?: string, until?: string) {
    let queryParams = new HttpParams();
    for (let adAccountId of adAccountIds) {
      queryParams = queryParams.append('adAccountId', adAccountId);
    }
    queryParams = queryParams.append('accessToken', accessToken);

    return this.httpClient.post<ResponseApi<ReachEngagementStatistical>>(
      `${this.baseUrl}/reach-engagement-statistical`,
      {
        since,
        until
      },
      {
        params: queryParams
      }
    );
  }

  getReachEngagementInsights(adAccountIds: string[], accessToken: string, since?: string, until?: string) {
    let queryParams = new HttpParams();
    for (let adAccountId of adAccountIds) {
      queryParams = queryParams.append('adAccountId', adAccountId);
    }
    queryParams = queryParams.append('accessToken', accessToken);
    return this.httpClient.post<ResponseApi<ReachEngagementInsights[]>>(
      `${this.baseUrl}/reach-engagement-insights`,
      {
        since,
        until
      },
      {
        params: queryParams
      }
    );
  }

  getPerformanceInsights(adAccountIds: string[], accessToken: string, since?: string, until?: string) {
    let queryParams = new HttpParams();
    for (let adAccountId of adAccountIds) {
      queryParams = queryParams.append('adAccountId', adAccountId);
    }
    queryParams = queryParams.append('accessToken', accessToken);
    return this.httpClient.post<ResponseApi<PerformanceInsights[]>>(
      `${this.baseUrl}/performance-insights`,
      {
        since,
        until
      },
      {
        params: queryParams
      }
    );
  }

  getStaticalPerformanceInsights(adAccountIds: string[], accessToken: string, since?: string, until?: string) {
    let queryParams = new HttpParams();
    for (let adAccountId of adAccountIds) {
      queryParams = queryParams.append('adAccountId', adAccountId);
    }
    queryParams = queryParams.append('accessToken', accessToken);
    return this.httpClient.post<ResponseApi<StaticalPerformanceInsights>>(
      `${this.baseUrl}/performance-overview-statistical`,
      {
        since,
        until
      },
      {
        params: queryParams
      }
    );
  }

  getAgeAndGenderAudienceInsights(
    adAccountIds: string[],
    accessToken: string,
    sortField: string,
    since?: string,
    until?: string
  ) {
    let queryParams = new HttpParams();
    for (let adAccountId of adAccountIds) {
      queryParams = queryParams.append('adAccountId', adAccountId);
    }
    queryParams = queryParams.append('accessToken', accessToken);
    queryParams = queryParams.append('sortField', sortField);
    return this.httpClient.post<ResponseApi<ReachEngagementInsights[]>>(
      `${this.baseUrl}/audience-insights`,
      {
        since,
        until
      },
      {
        params: queryParams
      }
    );
  }

  getDayAndTimeInsights(
    adAccountIds: string[],
    accessToken: string,
    sortField: string,
    since?: string,
    until?: string
  ) {
    let queryParams = new HttpParams();
    for (let adAccountId of adAccountIds) {
      queryParams = queryParams.append('adAccountId', adAccountId);
    }
    queryParams = queryParams.append('accessToken', accessToken);
    queryParams = queryParams.append('sortField', sortField);
    return this.httpClient.post<ResponseApi<ReachEngagementInsights[]>>(
      `${this.baseUrl}/day-time-insights`,
      {
        since,
        until
      },
      {
        params: queryParams
      }
    );
  }

  getPlacementInsights(adAccountIds: string[], accessToken: string, sortField: string, since?: string, until?: string) {
    let queryParams = new HttpParams();
    for (let adAccountId of adAccountIds) {
      queryParams = queryParams.append('adAccountId', adAccountId);
    }
    queryParams = queryParams.append('accessToken', accessToken);
    queryParams = queryParams.append('sortField', sortField);
    return this.httpClient.post<ResponseApi<ReachEngagementInsights[]>>(
      `${this.baseUrl}/placements-insights`,
      {
        since,
        until
      },
      {
        params: queryParams
      }
    );
  }

  getTopStatesInsights(adAccountIds: string[], accessToken: string, sortField: string, since?: string, until?: string) {
    let queryParams = new HttpParams();
    for (let adAccountId of adAccountIds) {
      queryParams = queryParams.append('adAccountId', adAccountId);
    }
    queryParams = queryParams.append('accessToken', accessToken);
    queryParams = queryParams.append('sortField', sortField);
    return this.httpClient.post<ResponseApi<ReachEngagementInsights[]>>(
      `${this.baseUrl}/top-states-insights`,
      {
        since,
        until
      },
      {
        params: queryParams
      }
    );
  }

  getDashboardColumns() {
    return this.httpClient.get<ResponseApi<string[]>>(`${this.baseUrl}/dashboard-columns`);
  }

  exportReport(
    adAccountId: string,
    accessToken: string,
    name: string,
    columnSelected: string[],
    since: string,
    until: string
  ) {
    let queryParams = new HttpParams({
      fromObject: {
        adAccountId,
        accessToken,
        name
      }
    });

    queryParams = columnSelected.reduce((params, column) => params.append('columnSelected', column), queryParams);

    return this.httpClient
      .post(
        `${this.baseUrl}/export`,
        {
          since,
          until
        },
        {
          params: queryParams,
          responseType: 'blob'
        }
      )
      .pipe(
        catchError((error: any) => {
          if (error?.error instanceof Blob) {
            return new Promise((_, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                try {
                  const errorJson = JSON.parse(reader.result as string);
                  reject(errorJson); // Trả về lỗi dạng JSON
                } catch (e) {
                  reject({ message: 'Unknown error', details: error });
                }
              };
              reader.readAsText(error.error);
            });
          }
          return throwError(() => error);
        })
      );
  }
}
