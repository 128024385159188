<div class="py-6">
  <h2 class="font-medium text-neutral-600 mb-4 text-4">
    {{ dashBoardPrefix + 'REACH-ENGAGEMENT-PERFORMANCE' | translate }}
  </h2>

  <div class="flex gap-6">
    @for (statistic of statistics; track $index) {
      <div class="flex-1">
        <app-dashboard-statistic
          [title]="dashBoardPrefix + statistic.id | translate"
          [amount]="statistic.amount"
          [rate]="statistic.rate"
          [dataType]="statistic.dataType"></app-dashboard-statistic>
      </div>
    }
  </div>

  <div class="mt-6 grid grid-cols-3 gap-6">
    <!-- Impressions -->
    <app-bar-line-chart
      [loading]="isLoading"
      [title]="dashBoardPrefix + 'impressions' | translate"
      [totalAmount]="totalImpressions"
      [dataType]="UNIT_NUMBER.NUMBER"
      [data]="impressions"
      [datesSelected]="datesSelected"></app-bar-line-chart>

    <!-- Clicks -->
    <app-bar-line-chart
      [loading]="isLoading"
      [title]="dashBoardPrefix + 'clicks_all' | translate"
      [totalAmount]="totalClicks"
      [dataType]="UNIT_NUMBER.NUMBER"
      [data]="clicks"
      [datesSelected]="datesSelected"></app-bar-line-chart>

    <!-- CTR -->
    <app-bar-line-chart
      [loading]="isLoading"
      [title]="dashBoardPrefix + 'ctr_all' | translate"
      [totalAmount]="(totalClicks / totalImpressions) * 100 || 0"
      [dataType]="UNIT_NUMBER.PERCENT"
      [data]="CTR"
      [datesSelected]="datesSelected"></app-bar-line-chart>
  </div>
</div>
