<div class="flex flex-row ml-auto justify-between items-center pt-6 pb-4">
  <span class="text-neutral-600 text-4 font-normal">{{
    dashBoardPrefix + 'audience_and_insights' | translate | uppercase
  }}</span>
</div>
<div class="grid grid-cols-2 gap-6">
  <!-- Age And Gender chart -->
  @if (isAgeAndGenderLoading) {
    <p-skeleton width="100%" height="100%" />
  } @else {
    <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
      <div>
        <div class="flex flex-row justify-between items-center">
          <h2 class="font-semibold text-4">{{ dashBoardPrefix + 'age_and_gender' | translate }}</h2>
          <p-dropdown
            styleClass="h-9 w-full flex ml-auto"
            [options]="generalDropdown"
            appendTo="body"
            optionLabel="name"
            optionValue="code"
            dropdownIcon="icon-wl-arrow-drop-down"
            [(ngModel)]="selectedDataTypeAgeAndGender"
            (onChange)="onChangeDataTypeAgeGender()">
            <ng-template let-item pTemplate="item">
              <div>{{ dashBoardPrefix + item.code | translate }}</div>
            </ng-template>
            <ng-template let-selectedItem pTemplate="selectedItem">
              <div *ngIf="selectedItem">
                {{ dashBoardPrefix + selectedItem.code | translate }}
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <span class="font-semibold text-7">{{
          isDisplayAsInteger(selectedDataTypeAgeAndGender)
            ? (totalAgeAndGenderData | number: '1.0-2')
            : selectedDataTypeAgeAndGender === AUDIENCE_INSIGHTS_DROPDOWN.CTR
              ? (totalAgeAndGenderData | number: '1.2-2') + '%'
              : (totalAgeAndGenderData | currency: 'USD')
        }}</span>

        <div class="flex-1 pt-5 pb-4">
          <p-chart
            height="256px"
            width="100%"
            type="bar"
            [data]="ageAndGenderChartData"
            [options]="barChartAgeGenderOptions"></p-chart>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="leadsByGender" [dashBoardTranslate]="true"></app-doughnut-chart-legend>
    </div>
  }

  <!-- Day and time chart -->
  @if (isDayAndTimeLoading) {
    <p-skeleton width="100%" height="100%" />
  } @else {
    <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
      <div>
        <div class="flex flex-row justify-between items-center">
          <h2 class="font-semibold text-4">{{ dashBoardPrefix + 'day_and_time' | translate }}</h2>
          <p-dropdown
            styleClass="h-9 w-full flex ml-auto"
            [options]="generalDropdown"
            appendTo="body"
            optionLabel="name"
            optionValue="code"
            dropdownIcon="icon-wl-arrow-drop-down"
            [(ngModel)]="selectedDataTypeDayAndTime"
            (onChange)="onChangeDataTypeDay()">
            <ng-template let-item pTemplate="item">
              <div>{{ dashBoardPrefix + item.code | translate }}</div>
            </ng-template>
            <ng-template let-selectedItem pTemplate="selectedItem">
              <div *ngIf="selectedItem">
                {{ dashBoardPrefix + selectedItem.code | translate }}
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <span class="font-semibold text-7 pb-5">{{
          isDisplayAsInteger(selectedDataTypeDayAndTime)
            ? (totalDayAndTimeData | number: '1.0-2')
            : selectedDataTypeDayAndTime === AUDIENCE_INSIGHTS_DROPDOWN.CTR
              ? (totalDayAndTimeData | number: '1.2-2') + '%'
              : (totalDayAndTimeData | currency: 'USD')
        }}</span>
        <app-heatmap [data]="dayAndTimeHeatMapData"></app-heatmap>
      </div>
    </div>
  }

  <!-- Top states chart -->
  @if (isTopStatesLoading) {
    <p-skeleton width="100%" height="100%" />
  } @else {
    <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
      <div>
        <div class="flex flex-row justify-between items-center">
          <h2 class="font-semibold text-4">{{ dashBoardPrefix + 'top_states' | translate }}</h2>
          <p-dropdown
            styleClass="h-9 w-full flex ml-auto"
            [options]="topStateDropdown"
            appendTo="body"
            optionLabel="name"
            optionValue="code"
            dropdownIcon="icon-wl-arrow-drop-down"
            [(ngModel)]="selectedDataTypeTopState"
            (onChange)="onChangeDataTypeTopState()">
            <ng-template let-item pTemplate="item">
              <div>{{ dashBoardPrefix + item.code | translate }}</div>
            </ng-template>
            <ng-template let-selectedItem pTemplate="selectedItem">
              <div *ngIf="selectedItem">
                {{ dashBoardPrefix + selectedItem.code | translate }}
              </div>
            </ng-template></p-dropdown
          >
        </div>
        <span class="font-semibold text-7 pb-7">{{
          isDisplayAsInteger(selectedDataTypeTopState)
            ? (totalTopStatesData | number: '1.0-2')
            : selectedDataTypeTopState === AUDIENCE_INSIGHTS_DROPDOWN.CTR
              ? (totalTopStatesData | number: '1.2-2') + '%'
              : (totalTopStatesData | currency: 'USD')
        }}</span>

        <div class="flex-1 pt-5 pb-4">
          <p-chart
            height="256px"
            width="100%"
            type="bar"
            [data]="topStatesChartData"
            [options]="barChartTopStateOptions"></p-chart>
        </div>
      </div>
    </div>
  }

  <!-- Placements chart -->
  @if (isPlacementLoading) {
    <p-skeleton width="100%" height="100%" />
  } @else {
    <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
      <div>
        <div class="flex flex-row justify-between items-center">
          <h2 class="font-semibold text-4">{{ dashBoardPrefix + 'placements' | translate }}</h2>
          <p-dropdown
            styleClass="h-9 w-full flex ml-auto"
            [options]="generalDropdown"
            appendTo="body"
            optionLabel="name"
            optionValue="code"
            dropdownIcon="icon-wl-arrow-drop-down"
            [(ngModel)]="selectedDataTypePlacements"
            (onChange)="onChangeDataTypePlacements()">
            <ng-template let-item pTemplate="item">
              <div>{{ dashBoardPrefix + item.code | translate }}</div>
            </ng-template>
            <ng-template let-selectedItem pTemplate="selectedItem">
              <div *ngIf="selectedItem">
                {{ dashBoardPrefix + selectedItem.code | translate }}
              </div>
            </ng-template></p-dropdown
          >
        </div>
        <span class="font-semibold text-7 pb-5">{{
          isDisplayAsInteger(selectedDataTypePlacements)
            ? (totalPlacementsData | number: '1.0-2')
            : selectedDataTypePlacements === AUDIENCE_INSIGHTS_DROPDOWN.CTR
              ? (totalPlacementsData | number: '1.2-2') + '%'
              : (totalPlacementsData | currency: 'USD')
        }}</span>

        <div class="flex-1 pt-5 pb-4">
          <p-chart
            height="256px"
            width="100%"
            type="bar"
            [data]="placementsChartData"
            [options]="barChartPlacementOptions"></p-chart>
        </div>
      </div>
    </div>
  }
</div>
