<div class="flex items-center gap-x-4 gap-y-3 flex-wrap justify-center">
  @for (option of options; track $index) {
    <div class="flex items-center gap-1.5">
      <span
        [ngClass]="option?.isLine ? 'h-[2px]' : 'h-3'"
        class="w-3 rounded-xs"
        [style.backgroundColor]="option.color"></span>
      @if (dashBoardTranslate) {
        <span [ngClass]="styleClass ? styleClass : 'max-w-[280px] truncate'">
          {{ 'dashboard.' + option.label.toLowerCase().replaceAll(' ', '_') | translate }}
        </span>
      } @else {
        <span [ngClass]="styleClass ? styleClass : 'max-w-32 truncate'">
          {{ option.label }}
        </span>
      }
    </div>
  }
</div>
