import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ChartOption } from '@core/models/interfaces/dashboard';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-doughnut-chart-legend',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './doughnut-chart-legend.component.html',
  styleUrl: './doughnut-chart-legend.component.scss'
})
export class DoughnutChartLegendComponent {
  @Input() options: ChartOption[] = [];
  @Input() styleClass: string = '';
  @Input() dashBoardTranslate: boolean = false;
}
