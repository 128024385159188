import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateFilterType } from '@core/enum/date';
import { FacebookAccount } from '@core/models/interfaces/facebook/facebook-account';
import { AdAccount, AdAccountWithBusiness } from '@core/models/interfaces/facebook/integration';
import {
  PerformanceInsights,
  ReachEngagementInsights,
  ReachEngagementStatistical,
  StaticalPerformanceInsights
} from '@core/models/interfaces/marketing-dashboard/dashboard';
import { FacebookAdsAdminService } from '@core/services/facebook.service.ts/facebook-ads-admin.service';
import { FacebookDashboardService } from '@core/services/facebook.service.ts/facebook-dashboard.service';
import { Store } from '@ngrx/store';
import { AdAccountSelectComponent } from '@shared/components/ad-account-select/ad-account-select.component';
import { CustomCalendarComponent } from '@shared/components/custom-calendar/custom-calendar.component';
import { AppState } from '@state/app.state';
import { selectFacebookAccountSelected } from '@state/auth/auth.selectors';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AudienceAndInsightsComponent } from './components/audience-and-insights/audience-and-insights.component';
import { ExportReportDashboardComponent } from './components/export-report-dashboard/export-report-dashboard.component';
import { PerformanceOverviewComponent } from './components/performance-overview/performance-overview.component';
import { ReachEngagementPerformanceComponent } from './components/reach-engagement-performance/reach-engagement-performance.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-marketing-dashboard',
  standalone: true,
  imports: [
    AudienceAndInsightsComponent,
    PerformanceOverviewComponent,
    ReachEngagementPerformanceComponent,
    AdAccountSelectComponent,
    CustomCalendarComponent,
    ExportReportDashboardComponent,
    TranslateModule
  ],
  templateUrl: './marketing-dashboard.component.html',
  styleUrl: './marketing-dashboard.component.scss'
})
export class MarketingDashboardComponent implements OnInit, OnDestroy {
  readonly unsubscribe$ = new Subject();
  readonly DateFilterType = DateFilterType;
  readonly currentDate = new Date();

  initial: boolean = false;
  facebookAccount: FacebookAccount;
  accountBusinesses: AdAccountWithBusiness[] = [];
  datesSelected: Date[] = [];
  adAccountsSelected: AdAccount[] = [];
  adAccountSelected: AdAccount = {
    id: '',
    name: ''
  };

  //data
  reachEngagementLoading: boolean = false;
  isAgeAndGenderLoading: boolean = false;
  isTopStatesLoading: boolean = false;
  isDayAndTimeLoading: boolean = false;
  isPlacementLoading: boolean = false;
  reachEngagementData: ReachEngagementInsights[] = [];
  ageAndGenderData: ReachEngagementInsights[] = [];
  placementData: ReachEngagementInsights[] = [];
  topStatesData: ReachEngagementInsights[] = [];
  dayAndTimeData: ReachEngagementInsights[] = [];
  reachEngagementStatistic: ReachEngagementStatistical;
  performanceData: PerformanceInsights[];
  staticalPerformanceData: StaticalPerformanceInsights;
  placementSortField: string = '';
  topStateSortField: string = '';
  performanceLoading: boolean = false;
  now = new Date();
  dateFilterAudienceAndInsights: { startDate: string; endDate: string } = {
    startDate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
    endDate: moment(new Date()).endOf('month').format('YYYY-MM-DD')
  };

  get dateFilter() {
    const startDate = moment(this.datesSelected[0]).format('YYYY-MM-DD');
    const endDate = moment(this.datesSelected[1]).format('YYYY-MM-DD');

    return {
      startDate,
      endDate
    };
  }

  constructor(
    private facebookAdsAdminService: FacebookAdsAdminService,
    private facebookDashboardService: FacebookDashboardService,
    private messageService: MessageService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    const now = new Date();
    this.datesSelected = [moment(now).startOf('month').toDate(), moment(now).endOf('month').toDate()];

    this.getAccessToken();
    this.initData();
  }

  getAccessToken() {
    this.store
      .select(selectFacebookAccountSelected)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(acc => {
        if (acc) {
          this.facebookAccount = acc;
        }
      });
  }

  initData() {
    if (!this.facebookAccount) return;
    const { accessToken, id } = this.facebookAccount;
    this.facebookAdsAdminService
      .getAdAccountsWithBusiness(accessToken, id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.accountBusinesses = res.data;
          if (this.accountBusinesses.length) {
            const adAccountStores = sessionStorage.getItem('adAccountInit');
            const adAccount = this.accountBusinesses?.[0].act_account?.[0];

            if (adAccountStores) {
              const adAccountSelected = JSON.parse(adAccountStores);
              const adAccounts = this.accountBusinesses.flatMap(item => item.act_account);
              if (adAccounts.find(item => item.id === adAccountSelected?.id)) {
                this.adAccountsSelected = [
                  {
                    id: JSON.parse(adAccountStores)?.id,
                    name: JSON.parse(adAccountStores)?.name
                  }
                ];
              } else {
                this.adAccountsSelected = [
                  {
                    id: adAccount.id,
                    name: adAccount.name
                  }
                ];
              }
            } else {
              this.adAccountsSelected = [
                {
                  id: adAccount.id,
                  name: adAccount.name
                }
              ];
            }
          }
          this.fetchData();
          this.initial = true;
        },
        error: () => {
          this.initial = true;
        }
      });
  }

  onChangeAdAccount() {
    this.fetchData();
  }

  datesSelectedChange() {
    this.fetchData();
  }

  fetchData() {
    this.unsubscribe$.next(null);
    this.fetchReachAndEngagementStatistic();
    this.fetchReachAndEngagementInsights();
    this.fetchPerformanceInsights();
    this.fetchStaticalPerformanceInsights();
    this.fetchAudienceAndInsights();
  }

  onDateFilterChange(event: { startDate: string; endDate: string }) {
    this.dateFilterAudienceAndInsights = event;
    this.fetchAudienceAndInsights();
  }

  fetchChartData(event: { type: string; sortField: string }) {
    switch (event.type) {
      case 'Age':
        return this.fetchAgeAndGenderData(event.sortField);
      case 'TopStates':
        return this.fetchTopStatesData(event.sortField);
      case 'DayAndTime':
        return this.fetchDayAndTimeData(event.sortField);
      case 'Placement':
        return this.fetchPlacementData(event.sortField);
    }
  }

  fetchAudienceAndInsights() {
    this.fetchDayAndTimeData('amount_spent');
    this.fetchAgeAndGenderData('amount_spent');
    this.fetchTopStatesData(this.topStateSortField || 'amount_spent');
    this.fetchPlacementData(this.placementSortField || 'amount_spent');
  }

  fetchDayAndTimeData(sortField: string) {
    const { startDate, endDate } = this.dateFilter;
    this.isDayAndTimeLoading = true;
    this.facebookDashboardService
      .getDayAndTimeInsights(
        this.adAccountsSelected.map(item => item.id),
        this.facebookAccount.accessToken,
        sortField,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: results => {
          this.dayAndTimeData = results.data;
          this.isDayAndTimeLoading = false;
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });
          this.isDayAndTimeLoading = false;
        }
      });
  }

  fetchAgeAndGenderData(sortField: string) {
    const { startDate, endDate } = this.dateFilter;
    this.isAgeAndGenderLoading = true;
    this.facebookDashboardService
      .getAgeAndGenderAudienceInsights(
        this.adAccountsSelected.map(item => item.id),
        this.facebookAccount.accessToken,
        sortField,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.ageAndGenderData = res.data;
          this.isAgeAndGenderLoading = false;
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });
          this.isAgeAndGenderLoading = false;
        }
      });
  }

  fetchPlacementData(sortField: string) {
    this.placementSortField = sortField;
    const { startDate, endDate } = this.dateFilter;
    this.isPlacementLoading = true;
    this.facebookDashboardService
      .getPlacementInsights(
        this.adAccountsSelected.map(item => item.id),
        this.facebookAccount.accessToken,
        sortField,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.placementData = res.data;
          this.isPlacementLoading = false;
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });
          this.isPlacementLoading = false;
        }
      });
  }

  fetchTopStatesData(sortField: string) {
    this.topStateSortField = sortField;
    const { startDate, endDate } = this.dateFilter;
    this.isTopStatesLoading = true;
    this.facebookDashboardService
      .getTopStatesInsights(
        this.adAccountsSelected.map(item => item.id),
        this.facebookAccount.accessToken,
        sortField,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.topStatesData = res.data;
          this.isTopStatesLoading = false;
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });
          this.isTopStatesLoading = false;
        }
      });
  }

  fetchReachAndEngagementStatistic() {
    const { startDate, endDate } = this.dateFilter;
    this.facebookDashboardService
      .getReachEngagementStatistical(
        this.adAccountsSelected.map(item => item.id),
        this.facebookAccount.accessToken,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          if (res.data) {
            this.reachEngagementStatistic = res.data;
          }
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });
        }
      });
  }

  fetchReachAndEngagementInsights() {
    const { startDate, endDate } = this.dateFilter;
    this.reachEngagementLoading = true;
    this.facebookDashboardService
      .getReachEngagementInsights(
        this.adAccountsSelected.map(item => item.id),
        this.facebookAccount.accessToken,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          if (res.data) {
            this.reachEngagementData = res.data;
          }
          this.reachEngagementLoading = false;
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });

          this.reachEngagementLoading = false;
        }
      });
  }

  fetchPerformanceInsights() {
    const { startDate, endDate } = this.dateFilter;
    this.performanceLoading = true;
    this.facebookDashboardService
      .getPerformanceInsights(
        this.adAccountsSelected.map(item => item.id),
        this.facebookAccount.accessToken,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          if (res?.data) {
            this.performanceData = res.data;
          }
          this.performanceLoading = false;
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });

          this.performanceLoading = false;
        }
      });
  }

  fetchStaticalPerformanceInsights() {
    const { startDate, endDate } = this.dateFilter;
    this.facebookDashboardService
      .getStaticalPerformanceInsights(
        this.adAccountsSelected.map(item => item.id),
        this.facebookAccount.accessToken,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          if (res?.data) {
            this.staticalPerformanceData = res.data;
          }
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
