import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DividerModule } from 'primeng/divider';
import { Subject } from 'rxjs';

import { FacebookDashboardService } from '@core/services/facebook.service.ts/facebook-dashboard.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { downloadFile } from '@shared/utils/dowloadFile.util';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import moment from 'moment';
import { EmptyComponent } from '@shared/components/empty/empty.component';

@Component({
  selector: 'app-export-report-dashboard',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DragDropModule,
    DividerModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    DialogModule,
    ButtonComponent,
    SearchInputComponent,
    CheckboxModule,
    FormControlComponent,
    InputTextModule,
    EmptyComponent
  ],
  templateUrl: './export-report-dashboard.component.html',
  styleUrl: './export-report-dashboard.component.scss'
})
export class ExportReportDashboardComponent implements OnInit {
  @Input() datesSelected: Date[] = [];
  @Input() accessToken: string = '';
  @Input() adAccountId?: string = '';

  readonly reportPrefix = 'report.';
  readonly translatePrefix = 'lead-management.';
  readonly messagePrefix = 'lead-management.message.';
  readonly unsubscribe$ = new Subject();

  exportName = new FormControl('', [Validators.required]);

  isExporting: boolean = false;
  isVisible: boolean = false;

  columnActivated = '';
  columnsSelected: string[] = [
    'Account Name',
    'Reach',
    'Impressions',
    'Results',
    'Amount spent',
    'Leads',
    'Messaging conversations started',
    'Cost per conversion',
    'Total Revenue',
    'ROAS (Return on Ad Spend)',
    'Result Rate',
    'Link clicks',
    'CPC (all)',
    'CTR (all)'
  ];

  allCols: string[] = [];
  columnsDisplay: string[] = [];

  constructor(
    private facebookDashboardService: FacebookDashboardService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.fetchAllColumns();
  }

  fetchAllColumns() {
    this.facebookDashboardService.getDashboardColumns().subscribe({
      next: res => {
        this.allCols = res.data;
        this.columnsDisplay = res.data;
      },
      error: () => {
        this.allCols = [];
        this.columnsDisplay = [];
      }
    });
  }

  onDropColumns(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columnsSelected, event.previousIndex, event.currentIndex);
  }

  onRemoveSelectedColumn(code: string) {
    this.columnsSelected = [...this.columnsSelected].filter(item => item !== code);
  }

  onSearchColumn(event: string) {
    const filterValue = event.toLowerCase();
    this.columnsDisplay = [
      ...new Set(this.allCols.map(item => String(item)).filter(item => item.toLowerCase().includes(filterValue)))
    ];
  }

  onExport() {
    this.exportName.markAsTouched();
    if (!this.exportName?.value) {
      return;
    }

    const startDate = moment(this.datesSelected[0]).format('YYYY-MM-DD');
    const endDate = moment(this.datesSelected[1]).format('YYYY-MM-DD');

    this.isExporting = true;

    this.facebookDashboardService
      .exportReport(
        this.adAccountId || '',
        this.accessToken,
        this.exportName?.value,
        this.columnsSelected,
        startDate,
        endDate
      )
      .subscribe({
        next: res => {
          this.isExporting = false;

          downloadFile(res as Blob, this.exportName?.value as string);
        },
        error: err => {
          this.isExporting = false;
          this.messageService.add({
            severity: 'error',
            detail: err?.error
          });
        }
      });
  }
}
