import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService implements OnDestroy {
  private idleTime: number = environment.IDLE_TIMEOUT;
  private timeoutId: any;
  public showWarning$ = new Subject<boolean>();

  constructor(private authService: AuthService) {
    this.initListeners();
    this.startTimer();
  }

  private initListeners(): void {
    ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach(event => {
      document.addEventListener(event, () => {
        this.resetTimer();
      });
    });
  }

  private startTimer(): void {
    this.timeoutId = setTimeout(() => {
      this.authService.logout();
    }, this.idleTime);
  }

  private resetTimer(): void {
    clearTimeout(this.timeoutId);
    this.showWarning$.next(false);
    this.startTimer();
  }

  public stayLoggedIn(): void {
    this.resetTimer();
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
    ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach(event => {
      document.removeEventListener(event, () => this.resetTimer());
    });
  }
}
