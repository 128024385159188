import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { COLOR, COLOR_LIGHT } from '@core/enum/color';
import { UNIT_NUMBER } from '@core/enum/dashboard';
import { ChartOption } from '@core/models/interfaces/dashboard';
import {
  PerformanceInsights,
  StaticalPerformanceInsights
} from '@core/models/interfaces/marketing-dashboard/dashboard';
import { DoughnutChartLegendComponent } from '@feature/dashboard/components/doughnut-chart-legend/doughnut-chart-legend.component';
import { DashboardStatisticComponent } from '@shared/components/dashboard-statistic/dashboard-statistic.component';
import { resizeArray } from '@shared/utils/resizeArray';
import { ChartData, TooltipItem } from 'chart.js';
import moment from 'moment';
import { ChartModule } from 'primeng/chart';
import { SkeletonModule } from 'primeng/skeleton';
import { BarLineChartComponent } from '../bar-line-chart/bar-line-chart.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import 'moment/locale/vi';
import 'moment/locale/en-gb';
@Component({
  selector: 'app-performance-overview',
  standalone: true,
  imports: [
    DashboardStatisticComponent,
    ChartModule,
    DoughnutChartLegendComponent,
    BarLineChartComponent,
    CommonModule,
    SkeletonModule,
    TranslateModule
  ],
  templateUrl: './performance-overview.component.html',
  styleUrl: './performance-overview.component.scss'
})
export class PerformanceOverviewComponent implements OnChanges {
  @Input() isLoading: boolean = false;
  @Input() staticalPerformanceData: StaticalPerformanceInsights = {
    conversionStatistical: {
      conversionLeads: {
        amount: 0,
        rate: 0
      },
      cpc: {
        amount: 0,
        rate: 0
      },
      total: {
        amount: 0,
        rate: 0
      }
    },
    messageStartedStatistical: {
      conversionMessage: {
        amount: 0,
        rate: 0
      },
      cpc: {
        amount: 0,
        rate: 0
      },
      total: {
        amount: 0,
        rate: 0
      }
    }
  };
  @Input() performanceData: PerformanceInsights[] = [];
  @Input() datesSelected: Date[] = [];

  readonly dashBoardPrefix = 'dashboard.';
  readonly UNIT_NUMBER = UNIT_NUMBER;
  performanceConversionLeads = [
    {
      id: 'Conversion_leads',
      title: 'Conversion leads',
      amount: 0,
      dataType: UNIT_NUMBER.NUMBER,
      isAll: false,
      rate: 0
    },
    {
      id: 'Total_cost',
      title: 'Total cost',
      amount: 0,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: 0
    },
    {
      id: 'Cost_per_conversion',
      title: 'Cost per conversion',
      amount: 0,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: 0
    }
  ];

  performanceConversionMessages = [
    {
      id: 'Conversion_messages',
      title: 'Conversion messages',
      amount: 0,
      dataType: UNIT_NUMBER.NUMBER,
      isAll: false,
      rate: 0
    },
    {
      id: 'Total_cost',
      title: 'Total cost',
      amount: 0,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: 0
    },
    {
      id: 'Cost_per_conversion',
      title: 'Cost per conversion',
      amount: 0,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: 0
    }
  ];

  leadsOptions: ChartOption[] = [
    {
      label: 'Conversion leads',
      data: 5,
      color: '#3B82F6'
    },
    {
      label: 'Cost per lead',
      data: 5,
      color: '#F97316',
      isLine: true
    }
  ];

  dataLeads: ChartData;
  dataConversion: ChartData;
  //Amount spent
  dataAmountSpent: ChartData;
  totalAmountSpent: number = 0;
  //Revenue
  conversionRevenue: ChartData;
  totalRevenue: number = 0;
  //Message
  conversionOption: ChartOption[] = [
    {
      label: 'Message conversation started',
      data: 5,
      color: '#8B5CF6'
    },
    {
      label: 'Cost per message',
      data: 5,
      color: '#F97316',
      isLine: true
    }
  ];

  doughnutOption: ChartOption[] = [
    {
      label: 'Message Conversion Value',
      data: 5,
      color: COLOR.BLUE
    },
    {
      label: 'Lead Form Conversion Value',
      data: 5,
      color: COLOR.GREEN
    },
    {
      label: 'Website Conversion Value',
      data: 5,
      color: COLOR.ORANGE
    }
  ];

  optionsLead = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          callback: function (value: number) {
            return `$${value}.00`;
          },
          stepSize: 2,
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          display: true, // Enable/disable grid lines
          lineWidth: 0.3, // Set the width of the grid lines
          drawTicks: false
        },
        border: {
          display: false
        }
      }
    }
  };

  optionsConversion = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          callback: function (value: number) {
            return `$${value}.00`;
          },
          stepSize: 2,
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          lineWidth: 0.3, // Set the width of the grid lines

          drawTicks: false
        },
        border: {
          display: false
        }
      }
    }
  };

  doughnutChartOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<'doughnut'>) {
            const total = tooltipItem?.dataset?.data?.reduce((a: number, b: number) => a + b, 0) || 0;
            return `${tooltipItem?.formattedValue} (${(((tooltipItem?.parsed || 0) / total) * 100).toFixed(2)}%)`;
          }
        }
      }
    }
  };

  language: string = (localStorage.getItem('lang') || 'en').replace(/"/g, '').trim();

  formatDateLabel(date: string, language: string): string {
    return moment(date, 'YYYY-MM-DD')
      .locale(language)
      .format(language === 'vi' ? 'DD MMM' : 'MMM DD');
  }

  constructor(private translator: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['performanceData']?.currentValue) {
      this.mapStatisticData();
    }
    if (changes?.['staticalPerformanceData']?.currentValue) {
      this.mapStaticalData();
    }
  }

  mapStatisticData() {
    // this.mapDataRevenue();
    this.mapSpendInsights();
    this.mapConversionLeads();
    this.mapConversionMessage();
  }

  mapStaticalData() {
    this.mapStatisticDataLeads();
    this.mapStatisticDataMessage();
  }

  mapStatisticDataLeads() {
    const { conversionLeads, total, cpc } = this.staticalPerformanceData?.conversionStatistical;
    this.performanceConversionLeads.forEach(statistic => {
      switch (statistic.id) {
        case 'Conversion_leads': {
          statistic.amount = conversionLeads?.amount;
          statistic.rate = conversionLeads?.rate;
          break;
        }

        case 'Total_cost': {
          statistic.amount = total?.amount;
          statistic.rate = total?.rate;
          break;
        }

        case 'Cost_per_conversion': {
          statistic.amount = cpc?.amount;
          statistic.rate = cpc?.rate;
          break;
        }
      }
    });
  }

  mapStatisticDataMessage() {
    const { conversionMessage, total, cpc } = this.staticalPerformanceData?.messageStartedStatistical;
    this.performanceConversionMessages.forEach(statistic => {
      switch (statistic.id) {
        case 'Conversion_messages': {
          statistic.amount = conversionMessage?.amount;
          statistic.rate = conversionMessage?.rate;
          break;
        }

        case 'Total_cost': {
          statistic.amount = total?.amount;
          statistic.rate = total?.rate;
          break;
        }

        case 'Cost_per_conversion': {
          statistic.amount = cpc?.amount;
          statistic.rate = cpc?.rate;
          break;
        }
      }
    });
  }

  mapDataRevenue() {
    if (this.performanceData?.length) {
      const leadForm = (this.totalAmountSpent = this.performanceData.reduce((prev, curr) => {
        return prev + (curr?.revenue.leadForm || 0);
      }, 0));
      const message = (this.totalAmountSpent = this.performanceData.reduce((prev, curr) => {
        return prev + (curr?.revenue.message || 0);
      }, 0));
      const website = (this.totalAmountSpent = this.performanceData.reduce((prev, curr) => {
        return prev + (curr?.revenue.website || 0);
      }, 0));

      this.totalRevenue = leadForm + message + website;
      this.conversionRevenue = {
        labels: [
          this.translator.instant(this.dashBoardPrefix + 'message_conversion_value'),
          this.translator.instant(this.dashBoardPrefix + 'lead_form_conversion_value'),
          this.translator.instant(this.dashBoardPrefix + 'website_conversion_value')
        ],
        datasets: [
          {
            data: [message, leadForm, website],
            backgroundColor: [COLOR.BLUE, COLOR.GREEN, COLOR.ORANGE],
            hoverBackgroundColor: [COLOR_LIGHT.BLUE, COLOR_LIGHT.GREEN, COLOR_LIGHT.ORANGE]
          }
        ]
      };
    } else {
      this.totalRevenue = 0;
      this.conversionRevenue = {
        labels: [],
        datasets: []
      };
    }
  }

  mapSpendInsights() {
    // const { spendInsights } = this.performanceData;
    if (this.performanceData.length) {
      this.totalAmountSpent = this.performanceData.reduce((prev, curr) => {
        return prev + (curr?.spend || 0);
      }, 0);
      const data = resizeArray(this.performanceData, 10);

      this.dataAmountSpent = {
        labels: data.map(item => this.formatDateLabel(item.date_start, this.language)),
        datasets: [
          {
            label: this.translator.instant(this.dashBoardPrefix + 'spend'),
            borderColor: '#3B82F6',
            borderWidth: 2,
            fill: false,
            tension: 0.05,
            data: data.map(item => item.spend || 0),
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHitRadius: 10,
            backgroundColor: '#3B82F6',
            borderRadius: 2
          }
        ]
      };
    } else {
      this.totalAmountSpent = 0;
      this.dataAmountSpent = {
        labels: [],
        datasets: []
      };
    }
  }

  mapConversionLeads() {
    if (this.performanceData?.length) {
      const data = resizeArray(this.performanceData, 30);
      this.dataLeads = {
        labels: data.map(item => this.formatDateLabel(item.date_start, this.language)),
        datasets: [
          {
            type: 'line',
            label: this.translator.instant(this.dashBoardPrefix + 'cost_per_lead'),
            borderColor: '#F97316',
            borderWidth: 2,
            fill: false,
            tension: 0.2,
            data: data.map(item => item?.conversion_lead?.cost_per_lead || 0),
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHitRadius: 10
          },
          {
            type: 'bar',
            label: this.translator.instant(this.dashBoardPrefix + 'conversion_leads'),
            backgroundColor: '#3B82F6',
            data: data.map(item => item?.conversion_lead?.total_lead_conversion || 0),
            borderColor: '#3B82F6',
            borderWidth: 2,
            borderRadius: 2,
            barThickness: 20
          }
        ]
      };
    } else {
      this.dataLeads = {
        labels: [],
        datasets: []
      };
    }
  }

  mapConversionMessage() {
    if (this.performanceData.length) {
      const data = resizeArray(this.performanceData, 30);

      this.dataConversion = {
        labels: data.map(item => this.formatDateLabel(item.date_start, this.language)),
        datasets: [
          {
            type: 'line',
            label: this.translator.instant(this.dashBoardPrefix + 'cost_per_message'),
            borderColor: '#F97316',
            borderWidth: 2,
            fill: false,
            tension: 0.2,
            data: data.map(item => item?.messageStarted?.cpm || 0),
            pointRadius: 0,
            pointHoverRadius: 5,
            pointHitRadius: 10
          },
          {
            type: 'bar',
            label: this.translator.instant(this.dashBoardPrefix + 'conversion_messages'),
            backgroundColor: '#8B5CF6',
            data: data.map(item => item?.messageStarted?.messageStarted || 0),
            borderColor: '#8B5CF6',
            borderWidth: 2,
            borderRadius: 2,
            barThickness: 20
          }
        ]
      };
    } else {
      this.dataConversion = {
        labels: [],
        datasets: []
      };
    }
  }
}
