import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { COLOR } from '@core/enum/color';
import { DateFilterType } from '@core/enum/date';
import { ChartOption } from '@core/models/interfaces/dashboard';
import { ReachEngagementInsights, WeekDataDTO } from '@core/models/interfaces/marketing-dashboard/dashboard';
import { DoughnutChartLegendComponent } from '@feature/dashboard/components/doughnut-chart-legend/doughnut-chart-legend.component';
import { capitalize } from '@feature/marketing-audience/utils';
import { ChartData, ChartDataset } from 'chart.js';
import moment from 'moment';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { SkeletonModule } from 'primeng/skeleton';
import { HeatMapComponent } from '../heatmap/heatmap.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AUDIENCE_INSIGHTS_DROPDOWN } from '@core/enum/dashboard';
@Component({
  selector: 'app-audience-and-insights',
  standalone: true,
  imports: [
    ChartModule,
    DoughnutChartLegendComponent,
    DropdownModule,
    CommonModule,
    FormsModule,
    HeatMapComponent,
    SkeletonModule,
    TranslateModule
  ],
  templateUrl: './audience-and-insights.component.html',
  styleUrl: './audience-and-insights.component.scss'
})
export class AudienceAndInsightsComponent {
  @Input() ageAndGenderData: ReachEngagementInsights[];
  @Input() dayAndTimeData: ReachEngagementInsights[];
  @Input() placementData: ReachEngagementInsights[];
  @Input() topStatesData: ReachEngagementInsights[];
  @Input() isAgeAndGenderLoading: boolean;
  @Input() isDayAndTimeLoading: boolean;
  @Input() isTopStatesLoading: boolean;
  @Input() isPlacementLoading: boolean;

  @Output() dataChange = new EventEmitter<{ type: string; sortField: string }>();

  readonly dashBoardPrefix = 'dashboard.';
  readonly currentDate = new Date();
  readonly DateFilterType = DateFilterType;
  readonly AUDIENCE_INSIGHTS_DROPDOWN = AUDIENCE_INSIGHTS_DROPDOWN;
  readonly barChartPlacementOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [5, 5]
        },
        ticks: {
          color: COLOR.GRAY
        }
      },
      y: {
        ticks: {
          callback: (value: number) => {
            if (this.isDisplayAsInteger(this.selectedDataTypePlacements)) {
              return value;
            } else if (this.selectedDataTypePlacements === AUDIENCE_INSIGHTS_DROPDOWN.CTR) {
              return `${value}%`;
            }
            return `$${value}.00`;
          },
          maxTicksLimit: 4
        },

        color: COLOR.GRAY,
        maxTicksLimit: 7
      }
    }
  };

  readonly barChartTopStateOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [5, 5]
        },
        ticks: {
          color: COLOR.GRAY
        }
      },
      y: {
        ticks: {
          callback: (value: number) => {
            if (this.isDisplayAsInteger(this.selectedDataTypeTopState)) {
              return value;
            } else if (this.selectedDataTypePlacements === AUDIENCE_INSIGHTS_DROPDOWN.CTR) {
              return `${value}%`;
            }
            return `$${value}.00`;
          },
          maxTicksLimit: 4
        },

        color: COLOR.GRAY,
        maxTicksLimit: 7
      }
    }
  };

  readonly barChartAgeGenderOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [5, 5]
        },
        ticks: {
          color: COLOR.GRAY
        }
      },
      y: {
        ticks: {
          callback: (value: number) => {
            if (this.isDisplayAsInteger(this.selectedDataTypeAgeAndGender)) {
              return value;
            } else if (this.selectedDataTypePlacements === AUDIENCE_INSIGHTS_DROPDOWN.CTR) {
              return `${value}%`;
            }
            return `$${value}.00`;
          },
          maxTicksLimit: 4
        },

        color: COLOR.GRAY,
        maxTicksLimit: 7
      }
    }
  };

  ageAndGenderChartData: ChartData;
  totalAgeAndGenderData: number = 0;
  topStatesChartData: ChartData;
  totalTopStatesData: number = 0;
  totalDayAndTimeData: number = 0;
  placementsChartData: ChartData;
  totalPlacementsData: number = 0;
  leadsByGender: ChartOption[] = [
    {
      label: 'MALE',
      data: 1,
      color: '#3B82F6'
    },
    {
      label: 'FEMALE',
      data: 0,
      color: '#EC4899'
    },
    {
      label: 'UNKNOWN',
      data: 0,
      color: '#B3B9C4'
    }
  ];

  topStateDropdown = [
    { name: 'Amount spent', code: AUDIENCE_INSIGHTS_DROPDOWN.AMOUNT_SPENT },
    { name: 'Conversion leads', code: AUDIENCE_INSIGHTS_DROPDOWN.CONVERSIONS_LEADS },
    { name: 'Average cost: CPC & CPL', code: AUDIENCE_INSIGHTS_DROPDOWN.AVERAGE_COST },
    { name: 'Impressions', code: AUDIENCE_INSIGHTS_DROPDOWN.IMPRESSIONS }
  ];
  generalDropdown = [
    { name: 'Amount spent', code: AUDIENCE_INSIGHTS_DROPDOWN.AMOUNT_SPENT },
    { name: 'Conversion', code: AUDIENCE_INSIGHTS_DROPDOWN.CONVERSIONS },
    { name: 'Message conversations started', code: AUDIENCE_INSIGHTS_DROPDOWN.MESSAGE_CONVERSATIONS_STARTED },
    { name: 'Cost per lead', code: AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_LEAD },
    { name: 'Total revenue', code: AUDIENCE_INSIGHTS_DROPDOWN.TOTAL_REVENUE },
    { name: 'Impressions', code: AUDIENCE_INSIGHTS_DROPDOWN.IMPRESSIONS },
    { name: 'Clicks (all)', code: AUDIENCE_INSIGHTS_DROPDOWN.CLICKS_ALL },
    { name: 'CTR', code: AUDIENCE_INSIGHTS_DROPDOWN.CTR },
    { name: 'Average CPC', code: AUDIENCE_INSIGHTS_DROPDOWN.AVERAGE_CPC },
    { name: 'Cost per engagement', code: AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_ENGAGEMENT },
    { name: 'Cost per Thruplay', code: AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_THRUPLAY }
  ];
  selectedDataTypeAgeAndGender: AUDIENCE_INSIGHTS_DROPDOWN = AUDIENCE_INSIGHTS_DROPDOWN.AMOUNT_SPENT;
  selectedDataTypeDayAndTime: AUDIENCE_INSIGHTS_DROPDOWN = AUDIENCE_INSIGHTS_DROPDOWN.AMOUNT_SPENT;
  selectedDataTypePlacements: AUDIENCE_INSIGHTS_DROPDOWN = AUDIENCE_INSIGHTS_DROPDOWN.AMOUNT_SPENT;
  selectedDataTypeTopState: AUDIENCE_INSIGHTS_DROPDOWN = AUDIENCE_INSIGHTS_DROPDOWN.AMOUNT_SPENT;
  datesSelected: Array<Date | undefined> = [];

  get dateFilter() {
    const startDate = moment(this.datesSelected[0]).format('YYYY-MM-DD');
    const endDate = moment(this.datesSelected[1]).format('YYYY-MM-DD');

    return {
      startDate,
      endDate
    };
  }

  dayAndTimeHeatMapData: WeekDataDTO[] = [];

  constructor(private translator: TranslateService) {}

  ngOnInit() {
    const now = new Date();
    this.datesSelected = [moment(now).startOf('month').toDate(), moment(now).endOf('month').toDate()];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['ageAndGenderData']?.currentValue) {
      this.mapAgeAndGenderData(this.ageAndGenderData, this.selectedDataTypeAgeAndGender);
    }
    if (changes?.['dayAndTimeData']?.currentValue) {
      this.mapDayAndTimeData(this.dayAndTimeData, this.selectedDataTypeDayAndTime);
    }
    if (changes?.['topStatesData']?.currentValue) {
      this.mapTopStatesData(this.topStatesData, this.selectedDataTypeTopState);
    }
    if (changes?.['placementData']?.currentValue) {
      this.mapPlacementsData(this.placementData, this.selectedDataTypePlacements);
    }
  }

  mapAgeAndGenderData(data: ReachEngagementInsights[], dataType: string) {
    const results = data.sort((a, b) => a.age.localeCompare(b.age));
    const labels = Array.from(new Set(data.map(item => item.age))).sort((a, b) => a.localeCompare(b));
    const maleData = labels.map(age => {
      const entry = results.find(item => item.age === age && item.gender === 'male');
      return entry ? entry[dataType] : 0;
    });

    const femaleData = labels.map(age => {
      const entry = results.find(item => item.age === age && item.gender === 'female');
      return entry ? entry[dataType] : 0;
    });

    const unknownData = labels.map(age => {
      const entry = results.find(item => item.age === age && item.gender === 'unknown');
      return entry ? entry[dataType] : 0;
    });

    const datasets: ChartDataset[] = [
      {
        label: this.translator.instant(this.dashBoardPrefix + 'male'),
        data: maleData,
        backgroundColor: COLOR.BLUE,
        maxBarThickness: 16,
        borderRadius: 2
      },
      {
        label: this.translator.instant(this.dashBoardPrefix + 'female'),
        data: femaleData,
        backgroundColor: COLOR.PINK,
        maxBarThickness: 16,
        borderRadius: 2
      },
      {
        label: this.translator.instant(this.dashBoardPrefix + 'unknown'),
        data: unknownData,
        backgroundColor: COLOR.GRAY,
        maxBarThickness: 16,
        borderRadius: 2
      }
    ];

    const totalAmountSpend = (results || [])
      .map(item => item?.[AUDIENCE_INSIGHTS_DROPDOWN.AMOUNT_SPENT] || 0)
      .reduce((init, cur) => init + cur, 0);

    const totalLead = (results || []).map(item => item?.['lead'] || 0).reduce((init, cur) => init + cur, 0) || 0;

    const totalClickAll = (results || [])
      .map(item => item?.[AUDIENCE_INSIGHTS_DROPDOWN.CLICKS_ALL] || 0)
      .reduce((init, cur) => init + cur, 0);

    const totalImpression = (results || [])
      .map(item => item?.[AUDIENCE_INSIGHTS_DROPDOWN.IMPRESSIONS] || 0)
      .reduce((init, cur) => init + cur, 0);

    const totalCostPerEngagement = (results || [])
      .map(item => item?.[AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_ENGAGEMENT] || 0)
      .reduce((init, cur) => init + cur, 0);

    const totalCostPerThruplay = (results || [])
      .map(item => item?.[AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_THRUPLAY] || 0)
      .reduce((init, cur) => init + cur, 0);

    switch (dataType) {
      case AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_LEAD:
        this.totalAgeAndGenderData = totalLead > 0 ? totalAmountSpend / totalLead : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.CTR:
        this.totalAgeAndGenderData = totalImpression > 0 ? totalClickAll / totalImpression : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.AVERAGE_CPC:
        this.totalAgeAndGenderData = totalClickAll > 0 ? totalAmountSpend / totalClickAll : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_ENGAGEMENT:
        this.totalAgeAndGenderData = totalCostPerEngagement > 0 ? totalAmountSpend / totalCostPerEngagement : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_THRUPLAY:
        this.totalAgeAndGenderData = totalCostPerThruplay > 0 ? totalAmountSpend / totalCostPerThruplay : 0;
        break;
      default:
        this.totalAgeAndGenderData = (results || [])
          .map(item => item?.[dataType] || 0)
          .reduce((init, cur) => init + cur, 0);
        break;
    }

    this.ageAndGenderChartData = {
      labels: labels.map(label =>
        label === 'Unknown' ? this.translator.instant(this.dashBoardPrefix + 'unknown') : label
      ),
      datasets
    };
  }

  mapDayAndTimeData(data: ReachEngagementInsights[], dataType?: string) {
    const daysMap: { [key: string]: string } = {
      Sunday: 'Sun',
      Monday: 'Mon',
      Tuesday: 'Tue',
      Wednesday: 'Wed',
      Thursday: 'Thu',
      Friday: 'Fri',
      Saturday: 'Sat'
    };

    const result: { [key: string]: number[] } = {
      Sun: new Array(24).fill(0),
      Mon: new Array(24).fill(0),
      Tue: new Array(24).fill(0),
      Wed: new Array(24).fill(0),
      Thu: new Array(24).fill(0),
      Fri: new Array(24).fill(0),
      Sat: new Array(24).fill(0)
    };

    data.forEach(item => {
      const day = daysMap[item?.day!];
      const hour = parseInt(item?.hourly_stats!?.split(':')[0]);
      result[day][hour] += (item[dataType as keyof ReachEngagementInsights] as number) ?? 0;
    });

    this.dayAndTimeHeatMapData = Object.keys(result).map(day => ({
      day: this.translator.instant('common.dayNamesShort.' + day),
      value: result[day]
    }));

    const totalAmountSpend = this.getTotalByDataTypeForDayAndTimeChart(data, AUDIENCE_INSIGHTS_DROPDOWN.AMOUNT_SPENT);

    const totalLead = this.getTotalByDataTypeForDayAndTimeChart(data, 'lead');

    const totalClickAll = this.getTotalByDataTypeForDayAndTimeChart(data, AUDIENCE_INSIGHTS_DROPDOWN.CLICKS_ALL);

    const totalImpression = this.getTotalByDataTypeForDayAndTimeChart(data, AUDIENCE_INSIGHTS_DROPDOWN.IMPRESSIONS);

    const totalCostPerEngagement = this.getTotalByDataTypeForDayAndTimeChart(
      data,
      AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_ENGAGEMENT
    );

    const totalCostPerThruplay = this.getTotalByDataTypeForDayAndTimeChart(
      data,
      AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_THRUPLAY
    );

    switch (dataType) {
      case AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_LEAD:
        this.totalDayAndTimeData = totalLead > 0 ? totalAmountSpend / totalLead : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.CTR:
        this.totalDayAndTimeData = totalImpression > 0 ? totalClickAll / totalImpression : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.AVERAGE_CPC:
        this.totalDayAndTimeData = totalClickAll > 0 ? totalAmountSpend / totalClickAll : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_ENGAGEMENT:
        this.totalDayAndTimeData = totalCostPerEngagement > 0 ? totalAmountSpend / totalCostPerEngagement : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_THRUPLAY:
        this.totalDayAndTimeData = totalCostPerThruplay > 0 ? totalAmountSpend / totalCostPerThruplay : 0;
        break;
      default:
        this.totalDayAndTimeData = Object.values(result)
          .flat()
          .reduce((sum, value) => sum + value, 0);
        break;
    }
  }

  getTotalByDataTypeForDayAndTimeChart(
    data: ReachEngagementInsights[],
    dataType: keyof ReachEngagementInsights
  ): number {
    const daysMap: { [key: string]: string } = {
      Sunday: 'Sun',
      Monday: 'Mon',
      Tuesday: 'Tue',
      Wednesday: 'Wed',
      Thursday: 'Thu',
      Friday: 'Fri',
      Saturday: 'Sat'
    };

    const result: { [key: string]: number[] } = {
      Sun: new Array(24).fill(0),
      Mon: new Array(24).fill(0),
      Tue: new Array(24).fill(0),
      Wed: new Array(24).fill(0),
      Thu: new Array(24).fill(0),
      Fri: new Array(24).fill(0),
      Sat: new Array(24).fill(0)
    };
    data.forEach(item => {
      const day = daysMap[item?.day!];
      const hour = parseInt(item?.hourly_stats!?.split(':')[0]);
      result[day][hour] += (item[dataType] as number) ?? 0;
    });

    return Object.values(result)
      .flat()
      .reduce((sum, value) => sum + value, 0);
  }

  getTotalByDataTypeForTopStateAndPlacementsChart(
    data: ReachEngagementInsights[],
    dataType: keyof ReachEngagementInsights
  ): number {
    const datasetData = data.map(item => Number(item[dataType as keyof ReachEngagementInsights] ?? 0));
    return datasetData.reduce((sum, value) => sum + (typeof value === 'number' ? value : 0), 0);
  }

  mapTopStatesData(data: ReachEngagementInsights[], dataType?: string) {
    const datasetData = data.map(item => Number(item[dataType as keyof ReachEngagementInsights] ?? 0));
    const totalAmountSpend = this.getTotalByDataTypeForTopStateAndPlacementsChart(
      data,
      AUDIENCE_INSIGHTS_DROPDOWN.AMOUNT_SPENT
    );
    const totalLead = this.getTotalByDataTypeForTopStateAndPlacementsChart(data, 'lead');
    const totalClickAll = this.getTotalByDataTypeForTopStateAndPlacementsChart(
      data,
      AUDIENCE_INSIGHTS_DROPDOWN.CLICKS_ALL
    );

    switch (dataType) {
      case AUDIENCE_INSIGHTS_DROPDOWN.AVERAGE_COST:
        this.totalTopStatesData =
          (totalClickAll > 0 ? totalAmountSpend / totalClickAll : 0) +
          (totalLead > 0 ? totalAmountSpend / totalLead : 0);
        break;
      default:
        this.totalTopStatesData = datasetData.reduce((sum, value) => sum + (typeof value === 'number' ? value : 0), 0);
        break;
    }

    this.topStatesChartData = {
      labels: data.map(item => item.region_name),
      datasets: [
        {
          label: this.getLabel(dataType),
          borderColor: '#3B82F6',
          borderWidth: 2,
          fill: false,
          barThickness: 20,
          tension: 0.05,
          data: datasetData,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10,
          backgroundColor: '#3B82F6',
          borderRadius: 2
        }
      ]
    };
  }

  mapPlacementsData(data: ReachEngagementInsights[], dataType?: string) {
    const datasetData = data.map(item => Number(item[dataType as keyof ReachEngagementInsights] ?? 0));

    const totalAmountSpend = this.getTotalByDataTypeForTopStateAndPlacementsChart(
      data,
      AUDIENCE_INSIGHTS_DROPDOWN.AMOUNT_SPENT
    );
    const totalLead = this.getTotalByDataTypeForTopStateAndPlacementsChart(data, 'lead');
    const totalClickAll = this.getTotalByDataTypeForTopStateAndPlacementsChart(
      data,
      AUDIENCE_INSIGHTS_DROPDOWN.CLICKS_ALL
    );
    const totalImpression = this.getTotalByDataTypeForTopStateAndPlacementsChart(
      data,
      AUDIENCE_INSIGHTS_DROPDOWN.IMPRESSIONS
    );
    const totalCostPerEngagement = this.getTotalByDataTypeForTopStateAndPlacementsChart(
      data,
      AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_ENGAGEMENT
    );
    const totalCostPerThruplay = this.getTotalByDataTypeForTopStateAndPlacementsChart(
      data,
      AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_THRUPLAY
    );

    switch (dataType) {
      case AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_LEAD:
        this.totalPlacementsData = totalLead > 0 ? totalAmountSpend / totalLead : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.CTR:
        this.totalPlacementsData = totalImpression > 0 ? totalClickAll / totalImpression : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.AVERAGE_CPC:
        this.totalPlacementsData = totalClickAll > 0 ? totalAmountSpend / totalClickAll : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_ENGAGEMENT:
        this.totalPlacementsData = totalCostPerEngagement > 0 ? totalAmountSpend / totalCostPerEngagement : 0;
        break;
      case AUDIENCE_INSIGHTS_DROPDOWN.COST_PER_THRUPLAY:
        this.totalPlacementsData = totalCostPerThruplay > 0 ? totalAmountSpend / totalCostPerThruplay : 0;
        break;
      default:
        this.totalPlacementsData = datasetData.reduce((sum, value) => sum + (typeof value === 'number' ? value : 0), 0);
        break;
    }

    this.placementsChartData = {
      labels: data.map(item => item.platform_position?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())),
      datasets: [
        {
          label: this.getLabel(dataType),
          borderColor: '#3B82F6',
          borderWidth: 2,
          fill: false,
          tension: 0.05,
          data: datasetData,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10,
          backgroundColor: '#3B82F6',
          borderRadius: 2
        }
      ]
    };
  }

  onChangeDataTypeTopState() {
    this.dataChange.emit({ type: 'TopStates', sortField: this.selectedDataTypeTopState });
    this.mapTopStatesData(this.topStatesData, this.selectedDataTypeTopState);
  }

  onChangeDataTypeAgeGender() {
    this.mapAgeAndGenderData(this.ageAndGenderData, this.selectedDataTypeAgeAndGender);
  }

  onChangeDataTypeDay() {
    this.mapDayAndTimeData(this.dayAndTimeData, this.selectedDataTypeDayAndTime);
  }

  onChangeDataTypePlacements() {
    this.dataChange.emit({ type: 'Placement', sortField: this.selectedDataTypePlacements });
    this.mapPlacementsData(this.placementData, this.selectedDataTypePlacements);
  }

  getLabel(dataType: string | null | undefined): string {
    if (!dataType?.trim()) return '';
    return this.translator.instant(`${this.dashBoardPrefix}${dataType}`);
  }

  isDisplayAsInteger(code: AUDIENCE_INSIGHTS_DROPDOWN): boolean {
    const integerFields = [
      AUDIENCE_INSIGHTS_DROPDOWN.MESSAGE_CONVERSATIONS_STARTED,
      AUDIENCE_INSIGHTS_DROPDOWN.CONVERSIONS,
      AUDIENCE_INSIGHTS_DROPDOWN.IMPRESSIONS,
      AUDIENCE_INSIGHTS_DROPDOWN.CLICKS_ALL
    ];
    return integerFields.includes(code);
  }
}
