import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxDocViewerModule, viewerType as ViewerType } from 'ngx-doc-viewer';
import { MessageService } from 'primeng/api';

import { TYPE_FILE } from '@core/enum/attachment';
import { APP_PERMISSION } from '@core/enum/permisson';
import { AttachmentInfo } from '@core/models/interfaces/attachment';
import { AttachmentService } from '@core/services/attachment.service';
import { StorageService } from '@core/services/storage.service';
import { FileIconDirective } from '@shared/directives/file-icon.directive';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { switchMap } from 'rxjs';
import { ButtonComponent } from '../button/button.component';
import { EmptyComponent } from '../empty/empty.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; // Import module

@Component({
  selector: 'app-preview-attachment',
  standalone: true,
  imports: [
    NgxDocViewerModule,
    FileIconDirective,
    EmptyComponent,
    ButtonComponent,
    TranslateModule,
    NgxPermissionsModule,
    PdfJsViewerModule
  ],
  templateUrl: './preview-attachment.component.html',
  styleUrl: './preview-attachment.component.scss'
})
export class PreviewAttachmentComponent {
  readonly messagePrefix = 'lead-details.message.';
  readonly TYPE_FILE = TYPE_FILE;
  readonly Permissions = APP_PERMISSION;

  url: string = '';
  viewer: ViewerType = 'url';
  file: AttachmentInfo;
  type: 'DOCUMENT' | 'IMAGE' | 'VIDEO' = 'DOCUMENT';
  isSupport = true;
  isNotFound = false;
  isConfidential: boolean = false;
  hasPermissionOpenConfidential: boolean = false;

  constructor(
    private storageService: StorageService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translator: TranslateService,
    private sanitizer: DomSanitizer,
    private permissionsService: NgxPermissionsService
  ) {}

  ngOnInit() {
    this.hasPermissionOpenConfidential = !!this.permissionsService.getPermission(
      APP_PERMISSION.CONFIDENTIAL_ATTACHMENT_OPEN
    );

    this.route.queryParams.subscribe(queryParams => {
      const id = queryParams?.['id'];
      if (id) {
        this.attachmentService
          .getAttachmentById(id)
          .pipe(
            switchMap(res => {
              this.isConfidential = res.data.confidential;
              return this.storageService.getFileInfo(res.data.path);
            })
          )
          .subscribe({
            next: res => {
              const fileType = res.data.type as TYPE_FILE;
              this.url = this.storageService.getFileUrl(res.data.id);
              this.file = res.data;

              if ([TYPE_FILE.DOCX, TYPE_FILE.XLSX].includes(fileType)) {
                this.viewer = 'office';
                this.type = 'DOCUMENT';
              } else if ([TYPE_FILE.PDF].includes(fileType)) {
                this.viewer = 'pdf';
                this.type = 'DOCUMENT';
              } else if ([TYPE_FILE.JPEG, TYPE_FILE.PNG].includes(fileType)) {
                this.type = 'IMAGE';
              } else if ([TYPE_FILE.MP4].includes(fileType)) {
                this.type = 'VIDEO';
              } else {
                this.isSupport = false;
              }
            },
            error: () => {
              this.isNotFound = true;
            }
          });
      }
    });
  }

  onDownloadFile() {
    if (!this.file) return;
    this.storageService.getFile(this.file.id).subscribe({
      next: res => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = this.file.originalName;
        link.click();
      },
      error: err => {
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error || this.translator.instant(this.messagePrefix + 'download-attachment-failed')
        });
      }
    });
  }
}
