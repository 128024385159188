import { Component } from '@angular/core';
import { MessageTemplateSample } from '@core/constants/message-flow';
import { FlowDetails, PartnerAppDetails } from '@core/models/interfaces/facebook/partner-apps';
import { FacebookMessageFlowService } from '@core/services/facebook.service.ts/message-flow.service';
import { FacebookPartnerAppService } from '@core/services/facebook.service.ts/partner-apps.service';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from '@shared/components/custom-paginator/custom-paginator.component';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MessageFlowViewComponent } from './components/message-flow-view/message-flow-view.component';
import { PartnerAppsViewComponent } from './components/partner-apps-view/partner-apps-view.component';

@Component({
  selector: 'app-partner-apps',
  standalone: true,
  imports: [AccordionModule, PartnerAppsViewComponent, MessageFlowViewComponent],
  templateUrl: './partner-apps.component.html',
  styleUrl: './partner-apps.component.scss'
})
export class PartnerAppsComponent {
  constructor(
    private facebookPartnerAppService: FacebookPartnerAppService,
    private FacebookMessageFlowService: FacebookMessageFlowService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {}

  partnerApps: PartnerAppDetails[] = [];
  currentPartnerAppActive: PartnerAppDetails | undefined;
  partnerAppActive: string = '';
  partnerAppName: string = '';
  isShowMessageFlow: boolean = false;
  isShowNotMessageFlow: boolean = false;
  messageFlowName: string = '';
  pagination: Pagination = {
    page: 0,
    size: 10
  };
  messageFlowActive: FlowDetails;
  keyword: string = '';

  ngOnInit() {
    this.onGetPartnerApps();
  }

  onCreatePartnerApps(event: string) {
    const body: { name: string } = {
      name: event
    };
    this.facebookPartnerAppService.createPartnerApp(body).subscribe({
      next: () => {
        this.onGetPartnerApps();
        this.messageService.add({
          severity: 'success',
          detail: 'Create partner app successfully'
        });
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: 'Create partner app failed'
        });
      }
    });
  }

  onSearchPartnerApp(event: string) {
    this.pagination.page = 0;
    this.keyword = event;
    this.onGetPartnerApps();
  }

  onDeletePartnerApps(event: { id: string, name: string}) {
    this.confirmationService.confirm({
      header: 'Delete app',
      message: `If you delete the ${event.name} app, all of its message flows will also be deleted.`,
      acceptButtonStyleClass: 'btn-xl btn-danger',
      rejectButtonStyleClass: 'btn-xl btn-outline-secondary',
      rejectLabel: this.translateService.instant('action.cancel'),
      acceptLabel: this.translateService.instant('action.delete'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      accept: () => {
        this.facebookPartnerAppService.deletePartnerApp(event.id).subscribe({
          next: () => {
            this.onGetPartnerApps();
            this.isShowMessageFlow = false;

            this.messageService.add({
              severity: 'success',
              detail: 'Delete partner app successfully'
            });
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              detail: error?.error?.error || 'Delete partner app failed'
            });
          }
        });
      }
    });
  }

  onGetPartnerApps() {
    this.facebookPartnerAppService.getPartnerApp(this.keyword, this.pagination.page, this.pagination.size).subscribe({
      next: res => {
        this.partnerApps = res.data.content;
      }
    });
  }

  onShowMessageFlow(event: FlowDetails) {
    this.messageFlowName = event.name;
    this.isShowMessageFlow = true;
    this.currentPartnerAppActive = undefined;
  }

  onDeleteMessageFlow(event: string) {
    this.confirmationService.confirm({
      header: 'Delete message flow',
      message: `Are you sure you want to delete this ${this.messageFlowName}?`,
      acceptButtonStyleClass: 'btn-xl btn-danger',
      rejectButtonStyleClass: 'btn-xl btn-outline-secondary',
      rejectLabel: this.translateService.instant('action.cancel'),
      acceptLabel: this.translateService.instant('action.delete'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      accept: () => {
        this.FacebookMessageFlowService.deleteMessageFlow(event).subscribe({
          next: () => {
            this.onGetPartnerApps();
            this.isShowMessageFlow = false;
            this.messageService.add({
              severity: 'success',
              detail: 'Delete message flow successfully'
            });
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              detail: error?.error?.error || 'Delete message flow failed'
            });
          }
        });
      }
    });
  }

  onCreateMessageFlow(partnerAppId: string) {
    const length = this.partnerApps.find(item => item.id === partnerAppId)?.flows?.length;

    const body = {
      name: 'New message flow' + (length && length > 0 ? ` ${length}` : ''),
      json: MessageTemplateSample,
      partnerAppId: partnerAppId
    };
    this.FacebookMessageFlowService.createMessageFlow(body).subscribe({
      next: () => {
        this.onGetPartnerApps();
        this.isShowNotMessageFlow = false;
        this.messageService.add({
          severity: 'success',
          detail: 'Create message flow successfully'
        });
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: 'Create message flow failed'
        });
      }
    });
  }

  onPartnerAppActiveChange() {
    this.isShowMessageFlow = false;
    this.currentPartnerAppActive = this.partnerApps.find(item => item.id === this.partnerAppActive);
    this.isShowNotMessageFlow = this.currentPartnerAppActive?.flows.length === 0;
  }

  onUpdateMessageFlow(event: { active: FlowDetails; name: string }) {
    const body = {
      name: event.name,
      json: event.active.json,
      partnerAppId: this.partnerAppActive
    };

    this.FacebookMessageFlowService.editMessageFlow(body, event.active.id).subscribe({
      next: () => {
        this.onGetPartnerApps();
        this.messageService.add({
          severity: 'success',
          detail: 'Update message flow successfully'
        });
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: 'Update message flow failed'
        });
      }
    });
  }
}
