<div class="flex flex-col px-8 pb-6 h-content max-h-content">
  <h1 class="font-semibold text-6 py-5">Integration</h1>

  <div class="flex-1 flex gap-5">
    <!--  Connect-->
    <div class="min-w-[350px] flex-1 px-2 border rounded-sm bg-white relative">
      <!--  Header-->
      <div class="flex justify-between items-center p-4">
        <div class="flex items-center gap-2">
          <img src="assets/images/icons/facebook.svg" class="w-6 h-6" />
          <span class="font-semibold text-4">Facebook</span>
          <span class="text-neutral-600">({{ accounts.length }}/5)</span>
        </div>

        @if (accounts.length < 5) {
          <app-button size="xs" label="Connect" icon="icon-wl-plus-circle" (onClick)="onConnect()"></app-button>
        }
      </div>

      <!-- Account-->
      <ul class="flex flex-col gap-1">
        @if (isConnecting) {
          <div class="absolute inset-0 bg-neutral-500/10 flex items-center justify-center rounded-sm">
            <p-progressSpinner styleClass="w-20 h-20"></p-progressSpinner>
          </div>
        }

        @if (accounts.length === 0) {
          <div class="p-4 text-center text-neutral-600">No connected account</div>
        } @else {
          @for (account of accounts; track account.id) {
            <li
              class="group px-3 py-2 flex items-center justify-between rounded-sm cursor-pointer"
              [ngClass]="{
                'text-primary-600 bg-primary-500/10': account.id === accountSelected.id,
                'hover:bg-neutral-50': account.id !== accountSelected.id
              }"
              (click)="onSelectAccount(account)">
              <div class="flex items-start gap-2">
                <p-radioButton [value]="account.id" [(ngModel)]="accountIdSelected"></p-radioButton>
                <div>
                  <div class="font-medium">{{ account.name }}</div>
                  <div class="text-2">ID: {{ account.id }}</div>
                </div>
              </div>
              @if (accounts.length > 1) {
                <div
                  class="hidden w-7 h-7 items-center justify-center rounded-sm cursor-pointer hover:bg-neutral-100"
                  [ngClass]="account.id === accountSelected.id ? '' : 'group-hover:flex'"
                  (click)="onDisconnectAccount($event, account.id, account.name)">
                  <i class="text-4 icon-wl-close"></i>
                </div>
              }
            </li>
          }
        }
      </ul>
    </div>

    <!--  Data-->
    <div class="flex-[3] flex flex-col gap-5 pb-4 max-h-[calc(100vh-124px)] overflow-y-auto">
      <ng-container
        [ngTemplateOutlet]="accountData"
        [ngTemplateOutletContext]="{
          code: 'FAN_PAGES',
          name: 'Fanpages',
          col1: 'Fanpage ID',
          col2: 'Fanpage name',
          data: fanPagesVisibility,
          isLoading: isFanPageLoading
        }"></ng-container>

      <ng-container
        [ngTemplateOutlet]="accountData"
        [ngTemplateOutletContext]="{
          code: 'BUSINESS',
          name: 'Business portfolio',
          col1: 'Account ID',
          col2: 'Name',
          data: businessesVisibility,
          isLoading: isBusinessLoading
        }"></ng-container>

      <ng-container
        [ngTemplateOutlet]="accountData"
        [ngTemplateOutletContext]="{
          code: 'AD_ACCOUNTS',
          name: 'Ad accounts',
          col1: 'Account ID',
          col2: 'Name',
          data: adAccountsVisibility,
          isLoading: isAdAccountLoading
        }"></ng-container>
    </div>
  </div>
</div>

<ng-template
  #accountData
  let-name="name"
  let-col1="col1"
  let-col2="col2"
  let-data="data"
  let-code="code"
  let-loading="isLoading">
  @if (loading && data.length === 0) {
    <p-table [value]="[1, 2, 3]" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="caption">
        <div class="font-semibold text-5">
          <p-skeleton width="5rem" />
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 50%">
            <p-skeleton width="5rem" />
          </th>
          <th style="width: 50%">
            <p-skeleton width="5rem" />
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <tr>
          <td>
            <p-skeleton width="10rem" />
          </td>
          <td>
            <p-skeleton width="10rem" />
          </td>
        </tr>
      </ng-template>
    </p-table>
  } @else {
    <p-table
      [value]="data"
      [loading]="loading"
      loadingIcon="pi pi-spin pi-spinner text-blue-600"
      [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="caption">
        <div class="flex items-center justify-between">
          <span class="font-semibold text-5">{{ name }}</span>

          <app-button
            variant="ghost-primary"
            icon="icon-wl-plus"
            iconPosition="left"
            label="Add {{ name }}"
            (onClick)="onAdd(code)"></app-button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: calc(100% / 3)">{{ col1 }}</th>
          <th style="width: calc(100% / 3)">{{ col2 }}</th>
          <th style="width: calc(100% / 3)"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{ item.id }}</td>
          <td>
            <div class="flex items-center gap-2">
              @if (item?.picture?.data) {
                <img class="w-6 h-6 rounded-full" [src]="item.picture.data?.url" [alt]="item.name" />
              }
              <span>{{ item.name }} </span>
            </div>
          </td>
          <td class="!text-right">
            <app-button variant="ghost-secondary" label="Remove" (onClick)="onRemove(item.id, code)"></app-button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">
            <app-empty-table />
          </td>
        </tr>
      </ng-template>
    </p-table>
  }
</ng-template>

<p-confirmDialog class="delete" />

<p-dialog
  [(visible)]="isModalAdd"
  [style]="{
    'min-width': '680px',
    width: '60vw',
    height: '40vh'
  }"
  [focusOnShow]="false"
  [draggable]="false"
  [modal]="true"
  [header]="(this.addSection === 'AD_ACCOUNTS' ? 'Add ' : 'Add a ') + addHeader">
  <div class="flex flex-col gap-4">
    <div>
      @switch (this.addSection) {
        @case ('FAN_PAGES') {
          Add a Facebook Page if your business already owns the Page. If you work for an agency, you should request your
          client's Pages
        }
        @case ('AD_ACCOUNTS') {
          Add ad accounts if your business will control campaigns and billing for this ad account. If you work for an
          agency, you should request access to your client’s ad account instead.
        }
      }
    </div>

    @if (this.addSection === 'AD_ACCOUNTS') {
      <p-multiSelect
        panelStyleClass="h-[50vh]"
        [placeholder]="'Select ' + addHeader"
        appendTo="body"
        styleClass="w-full"
        [(ngModel)]="adAccountIds"
        optionLabel="name"
        optionValue="id"
        [options]="adAccounts"
        [loading]="isAdAccountLoading"></p-multiSelect>
    } @else {
      <p-dropdown
        [placeholder]="'Select a ' + addHeader"
        appendTo="body"
        styleClass="w-full"
        [(ngModel)]="itemAddId"
        optionLabel="name"
        optionValue="id"
        [options]="addOptions"></p-dropdown>
    }
  </div>

  <ng-template pTemplate="footer">
    <app-button label="Cancel" variant="outline-secondary" size="xl" (onClick)="onCancelAdd()"></app-button>
    <app-button
      [label]="this.addSection === 'AD_ACCOUNTS' ? 'Confirm' : 'Add'"
      size="xl"
      (onClick)="onConfirmAdd()"></app-button>
  </ng-template>
</p-dialog>
