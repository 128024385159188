import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TableModule } from 'primeng/table';

import { Clipboard } from '@angular/cdk/clipboard';
import { RouterModule } from '@angular/router';
import { LEAD_TYPE } from '@core/enum/lead';
import { ProductionSortFieldEnum } from '@core/enum/report';
import {
  GetProductDetailsReportParams,
  ProductDetailsReport,
  ProductItemReportData
} from '@core/models/interfaces/report';
import { ReportService } from '@core/services/report.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomPaginatorComponent } from '@shared/components/custom-paginator/custom-paginator.component';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { SortTableHeaderComponent } from '@shared/components/sort-table-header/sort-table-header.component';
import { FrozenColStyleDirective } from '@shared/directives/frozen-col-style.directive';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-product-item-report',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    TranslateModule,
    CustomPaginatorComponent,
    EmptyTableComponent,
    FrozenColStyleDirective,
    SortTableHeaderComponent,
    RouterModule
  ],
  templateUrl: './product-item-report.component.html',
  styleUrl: './product-item-report.component.scss'
})
export class ProductItemReportComponent implements OnChanges {
  @Input() report: ProductDetailsReport;
  @Input() params: GetProductDetailsReportParams;
  @Input() groupBy: string = '';
  @Input() index: number = 0;

  readonly translatePrefix = 'lead-details.policy.';
  readonly ProductionSortFieldEnum = ProductionSortFieldEnum;
  readonly LeadType = LEAD_TYPE;

  isExpanded: boolean = true;
  data: ProductItemReportData[] = [];
  pagination = {
    page: 0,
    size: 10
  };
  options: number[] = [5, 10, 20, 100];
  sortField: string = '';
  sortOrder: number = 0;
  isLoading: boolean = false;

  constructor(
    private reportService: ReportService,
    private messageService: MessageService,
    private clipboard: Clipboard,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['report']?.currentValue) {
      this.data = [...this.report.data.content];
      this.pagination = {
        size: 10,
        page: 0
      };
    }
  }

  onToggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  onPagination() {
    this.isLoading = true;
    this.reportService
      .getProductDetailsReportById(this.report.id, {
        ...this.params,
        groupBy: this.groupBy,
        page: this.pagination.page,
        size: this.pagination.size
      })
      .subscribe({
        next: res => {
          this.data = res.data.content;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  onSort(event: { field: string; order: number }) {
    if (this.isLoading) return;
    this.sortField = event.field;
    this.sortOrder = event.order;
  }

  onCopy(value: string, label: string) {
    this.clipboard.copy(value.replaceAll('|', ''));
    this.messageService.add({
      severity: 'success',
      detail: this.translateService.instant('lead-details.overview-detail.copy-success', {
        value: this.translateService.instant('lead-details.overview-detail.' + label)
      })
    });
  }
}
