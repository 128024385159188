<div class="flex flex-col gap-3 border rounded-md p-4">
  <div class="flex items-center gap-2">
    <i
      class="icon-wl-arrow-drop-down text-6 cursor-pointer transition"
      [ngClass]="isExpanded ? '' : '-rotate-90'"
      (click)="onToggleExpanded()"></i>
    <span class="text-4 font-semibold">
      {{ report.name === 'ALL' ? report.name : index + '. ' + report.name }}
    </span>
    <div
      class="h-6 min-w-6 px-1 w-fit flex items-center justify-center text-neutral-800 bg-neutral-300 rounded-sm font-medium">
      {{ report.data.total }}
    </div>
  </div>

  @if (isExpanded) {
    <p-table [value]="data" [scrollable]="true">
      <ng-template pTemplate="header">
        <tr class="truncate">
          <th [pSortableColumn]="ProductionSortFieldEnum.POLICY_NO">
            <app-sort-table-header
              [label]="translatePrefix + 'policy-no' | translate"
              [field]="ProductionSortFieldEnum.POLICY_NO"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.LEAD">
            <app-sort-table-header
              [label]="translatePrefix + 'lead' | translate"
              [field]="ProductionSortFieldEnum.LEAD"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.LEAD_ID">
            <app-sort-table-header
              [label]="translatePrefix + 'lead-id' | translate"
              [field]="ProductionSortFieldEnum.LEAD_ID"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.CREATED_DATE">
            <app-sort-table-header
              [label]="translatePrefix + 'created-date' | translate"
              [field]="ProductionSortFieldEnum.CREATED_DATE"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.OWNER">
            <app-sort-table-header
              [label]="translatePrefix + 'owner' | translate"
              [field]="ProductionSortFieldEnum.OWNER"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.AGENT">
            <app-sort-table-header
              [label]="translatePrefix + 'agent' | translate"
              [field]="ProductionSortFieldEnum.AGENT"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.POLICY_STATUS">
            <app-sort-table-header
              [label]="translatePrefix + 'policy-status' | translate"
              [field]="ProductionSortFieldEnum.POLICY_STATUS"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.POLICY_TRACKING">
            <app-sort-table-header
              [label]="translatePrefix + 'policy-tracking' | translate"
              [field]="ProductionSortFieldEnum.POLICY_TRACKING"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.EFFECTIVE_DATE">
            <app-sort-table-header
              [label]="translatePrefix + 'effective-date' | translate"
              [field]="ProductionSortFieldEnum.EFFECTIVE_DATE"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.MODE">
            <app-sort-table-header
              [label]="translatePrefix + 'premium-mode' | translate"
              [field]="ProductionSortFieldEnum.MODE"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th class="!text-right" [pSortableColumn]="ProductionSortFieldEnum.PAYMENT">
            <app-sort-table-header
              [label]="translatePrefix + 'premium-payment' | translate"
              [field]="ProductionSortFieldEnum.PAYMENT"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th class="!text-right" [pSortableColumn]="ProductionSortFieldEnum.FACE_AMOUNT">
            <app-sort-table-header
              [label]="translatePrefix + 'face-amount' | translate"
              [field]="ProductionSortFieldEnum.FACE_AMOUNT"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.COMPANY">
            <app-sort-table-header
              [label]="translatePrefix + 'company' | translate"
              [field]="ProductionSortFieldEnum.COMPANY"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.PRODUCT_TYPE">
            <app-sort-table-header
              [label]="translatePrefix + 'product-type' | translate"
              [field]="ProductionSortFieldEnum.PRODUCT_TYPE"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.PRODUCT_NAME">
            <app-sort-table-header
              [label]="translatePrefix + 'product-name' | translate"
              [field]="ProductionSortFieldEnum.PRODUCT_NAME"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.CONTRIBUTION_YEAR">
            <app-sort-table-header
              [label]="translatePrefix + 'contribution-years' | translate"
              [field]="ProductionSortFieldEnum.CONTRIBUTION_YEAR"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.COMMISSION_TEAM">
            <app-sort-table-header
              [label]="translatePrefix + 'commission-team' | translate"
              [field]="ProductionSortFieldEnum.COMMISSION_TEAM"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.COMMISSION_PERCENTAGE">
            <app-sort-table-header
              [label]="translatePrefix + 'commission-percentage' | translate"
              [field]="ProductionSortFieldEnum.COMMISSION_PERCENTAGE"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.COMMISSION_OWNER">
            <app-sort-table-header
              [label]="translatePrefix + 'commission-owner' | translate"
              [field]="ProductionSortFieldEnum.COMMISSION_OWNER"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th [pSortableColumn]="ProductionSortFieldEnum.COMMISSION_AGENT">
            <app-sort-table-header
              [label]="translatePrefix + 'commission-agent' | translate"
              [field]="ProductionSortFieldEnum.COMMISSION_AGENT"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th>{{ translatePrefix + 'description' | translate }}</th>
          <th [pSortableColumn]="ProductionSortFieldEnum.CREATED_BY">
            <app-sort-table-header
              [label]="translatePrefix + 'created-by' | translate"
              [field]="ProductionSortFieldEnum.CREATED_BY"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th
            class="!text-right"
            pFrozenColumn
            [frozen]="true"
            alignFrozen="right"
            frozenColStyle="right"
            [pSortableColumn]="ProductionSortFieldEnum.TARGET">
            <app-sort-table-header
              [label]="translatePrefix + 'target-premium' | translate"
              [field]="ProductionSortFieldEnum.TARGET"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="truncate">
          <!-- Policy No -->
          <td>{{ item.number }}</td>

          <!-- Lead -->
          <td>
            <div class="flex items-center">
              <a
                class="underline text-blue-500"
                [routerLink]="['/lead-management/lead-details', item.lead?.id, 'overview']"
                target="_blank">
                {{ item.lead?.fullName }}</a
              >
            </div>
          </td>

          <!-- Lead ID -->
          <td>
            <div class="relative flex items-center w-max group">
              <span class="pr-6">{{ item.lead.leadId }}</span>
              <div
                class="absolute inset-0 flex items-center gap-1 text-4 opacity-0 group-hover:opacity-100 justify-center">
                <div class="icon-action">
                  <i (click)="onCopy(item.lead.leadId, 'lead-id')" class="icon-wl-duplicate cursor-pointer"></i>
                </div>
              </div>
            </div>
          </td>

          <!-- Created date -->
          <td>{{ item.createdAt | date: 'MM/dd/yyyy' }}</td>

          <!-- Owner -->
          <td>{{ item.owner?.fullName || '--' }}</td>

          <!-- Agent -->
          <td>{{ item.agent?.fullName || '--' }}</td>

          <!-- Policy status -->
          <td>{{ item.status?.name || '--' }}</td>

          <!-- Policy tracking -->
          <td>{{ item.policyTracking?.name || '--' }}</td>

          <!-- Effective date -->
          <td>{{ (item.effectiveDate | date: 'MM/dd/yyyy') || '--' }}</td>

          <!-- Premium mode -->
          <td>{{ item.mode?.name || '--' }}</td>

          <!-- Premium payment -->
          <td class="text-center">{{ (item.payment | currency) || '--' }}</td>

          <!-- Face amount -->
          <td class="text-center">{{ (item.faceAmount | currency) || '--' }}</td>

          <!-- Company -->
          <td>{{ item.product?.productType?.company?.name || '--' }}</td>

          <!-- Product type -->
          <td>{{ item.product?.productType?.name || '--' }}</td>

          <!-- Product name -->
          <td>{{ item.product?.name || '--' }}</td>

          <!-- Contribution years -->
          <td>{{ item.contributionYear || '--' }}</td>

          <!-- Commission team -->
          <td>{{ item.commissionTeam?.name || '--' }}</td>

          <!-- Commission percentage -->
          <td>{{ item.commissionPercentage?.name || '--' }}</td>

          <!-- Commission owner -->
          <td>{{ item.commissionOwner?.fullName || '--' }}</td>

          <!-- Commission agent -->
          <td>{{ item.commissionAgent?.fullName || '--' }}</td>

          <!-- Description -->
          <td>
            <div class="max-w-[360px] truncate">{{ item.note || '--' }}</div>
          </td>

          <!-- Created by -->
          <td>{{ item.createdBy?.fullName }}</td>

          <!-- Target premium -->
          <td class="text-center" pFrozenColumn [frozen]="true" alignFrozen="right" frozenColStyle="right">
            {{ item.target | currency }}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="summary">
        <div class="px-3.5 border-t flex h-11 items-center justify-end bg-neutral-alpha-200A">
          {{ report.totalTarget | currency }}
        </div>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="20">
            <app-empty-table></app-empty-table>
          </td>
        </tr>
      </ng-template>
    </p-table>

    @if (report.data.total > 5) {
      <app-custom-paginator
        [(pagination)]="pagination"
        [totalRecords]="report.data.total"
        [options]="options"
        (onChange)="onPagination()"></app-custom-paginator>
    }
  }
</div>
