import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { OverlayModule } from 'primeng/overlay';

import { UserInfo } from '@core/models/interfaces/auth';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppState } from '@state/app.state';
import { selectUserInfo } from '@state/auth/auth.selectors';
import { setIsSidebar } from '@state/system/system.actions';

import { FormsModule } from '@angular/forms';
import { BANNER_LOCATION } from '@core/enum/admin-content';
import { BannerDetail } from '@core/models/interfaces/admin-content/admin-content';
import { BannerService } from '@core/services/amin-content/banner.service';
import { AuthService } from '@core/services/auth.service';
import { LeadSearchService } from '@core/services/lead-search.service';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomAvatarComponent } from '../custom-avatar/custom-avatar.component';
import { CustomImageComponent } from '../custom-image/custom-image.component';
import { FlagsComponent } from '../flags/flags.component';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule,
    BadgeModule,
    TranslateModule,
    AvatarModule,
    CommonModule,
    FlagsComponent,
    OverlayModule,
    CustomAvatarComponent,
    NotificationComponent,
    CustomImageComponent,
    FormsModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  @ViewChild('flags') flagComponent: FlagsComponent;

  readonly environment = environment;
  readonly unsubscribe$ = new Subject();
  readonly translatePrefix = 'lead-management.';

  SMALL_SCREEN = 1280;
  isSmallScreen = false;
  visible = false;
  userInfo: UserInfo;
  keyword: string;

  logo: { data?: BannerDetail; loading: boolean } = {
    data: undefined,
    loading: false
  };

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService,
    private bannerService: BannerService,
    private authService: AuthService,
    private leadSearchService: LeadSearchService,
    private router: Router
  ) {}

  @HostListener('window:resize', ['$event'])
  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.SMALL_SCREEN;
  }

  @HostListener('window:scroll', ['$event'])
  scroll() {
    this.visible = false;
  }

  ngOnInit(): void {
    this.checkSmallScreen();
    this.fetchLogo();

    this.store.select(selectUserInfo).subscribe(userInfo => {
      if (userInfo) {
        this.userInfo = userInfo;
        if (this.flagComponent) {
          const lang = this.flagComponent.languages.find(el => el.language === userInfo.language);
          this.flagComponent.selectedLanguage = lang!;
          this.translateService.use(userInfo.language!);
        }
      }
    });
  }

  setSidebar() {
    this.store.dispatch(setIsSidebar({ isSidebar: true }));
  }

  onSignOut() {
    this.authService.logout();
  }

  onKeydownEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.router.navigate(['/lead-management']);
      this.leadSearchService.onSearchLeads(this.keyword, true);
    }
  }

  fetchLogo() {
    this.bannerService
      .getBannerManagement()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.logo = {
            data: res?.data?.content.find(el => el.location.replace(/\s+/g, '').toUpperCase() === BANNER_LOCATION.LOGO),
            loading: false
          };
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next('Complete');
    this.unsubscribe$.complete();
  }
}
