import { APP_PERMISSION } from '@core/enum/permisson';
import { NavigationItem } from '@core/models/interfaces/system';

export const SIDEBAR: NavigationItem[] = [
  {
    title: 'sidebar.home',
    icon: 'icon-wl-home',
    path: 'home'
  },
  {
    title: 'sidebar.dashboard',
    icon: 'icon-wl-vertical-bar-chart-square',
    path: 'dashboard',
    permissions: [
      APP_PERMISSION.DASHBOARD_LEAD_VIEW,
      APP_PERMISSION.DASHBOARD_PERFORMANCE_VIEW,
      APP_PERMISSION.DASHBOARD_POLICY_VIEW,
      APP_PERMISSION.DASHBOARD_USER_VIEW
    ]
  },
  {
    title: 'sidebar.sales-hub',
    icon: 'icon-wl-users',
    path: 'sales-hub',
    permissions: [APP_PERMISSION.SALES_HUB_VIEW],
    children: [
      {
        title: 'sidebar.leads',
        icon: 'icon-wl-users',
        path: 'lead-management',
        permissions: []
      },
      {
        title: 'sidebar.policies',
        icon: 'icon-wl-shield-check',
        path: 'policy',
        permissions: [APP_PERMISSION.POLICY_MANAGEMENT_VIEW]
      },
      {
        title: 'sidebar.notes',
        icon: 'icon-wl-note-alt',
        path: 'notes',
        permissions: [APP_PERMISSION.NOTE_MANAGEMENT_VIEW]
      },
      {
        title: 'sidebar.report',
        icon: 'icon-wl-vertical-bar-chart-square',
        path: 'report',
        permissions: [
          APP_PERMISSION.REPORT_PRODUCTION_DETAILS_RUN,
          APP_PERMISSION.REPORT_PERFORMANCE_RUN,
          APP_PERMISSION.REPORT_LEAD_RUN
        ]
      }
    ]
  },
  {
    title: 'sidebar.marketing',
    module: 'MARKETING',
    icon: 'icon-wl-speakerphone',
    path: '',
    permissions: [APP_PERMISSION.MARKETING_VIEW],
    children: [
      {
        title: 'sidebar.dashboard',
        path: 'marketing-dashboard',
        permissions: [APP_PERMISSION.MKT_DASHBOARD]
      },
      {
        title: 'sidebar.campaigns',
        path: 'marketing-campaigns',
        permissions: [APP_PERMISSION.MKT_CAMPAIGNS]
      },
      {
        title: 'sidebar.audiences',
        path: 'marketing-audiences',
        permissions: [APP_PERMISSION.MKT_AUDIENCES]
      },
      {
        title: 'sidebar.data-sources',
        path: 'data-sources',
        permissions: [APP_PERMISSION.MKT_DATA_SOURCES],
        environments: ['LOCAL', 'DEV']
      },
      {
        title: 'sidebar.billing-payments',
        path: 'marketing-payments',
        permissions: [APP_PERMISSION.MKT_BILLING_PAYMENTS]
      },
      {
        title: 'sidebar.integration',
        path: 'marketing-integration',
        permissions: [APP_PERMISSION.MKT_INTEGRATION]
      }
    ]
  },
  {
    title: 'sidebar.tasks',
    icon: 'icon-wl-file-check',
    path: 'tasks',
    permissions: [APP_PERMISSION.TASK_MANAGEMENT_VIEW, APP_PERMISSION.APPOINTMENT_VIEW]
  },

  {
    title: 'sidebar.settings',
    icon: 'icon-wl-setting',
    path: 'setting',
    permissions: [APP_PERMISSION.SETTINGS_VIEW],
    children: [
      {
        title: 'sidebar.data-config',
        path: 'data-config',
        permissions: [APP_PERMISSION.DATA_CONFIGURATION]
      },
      {
        title: 'sidebar.permissions',
        path: 'permissions',
        permissions: [APP_PERMISSION.PERMISSIONS_VIEW]
      },
      {
        title: 'sidebar.user-groups',
        path: 'user-groups',
        permissions: [APP_PERMISSION.USER_GROUPS_VIEW]
      },
      {
        title: 'sidebar.company-organization',
        path: 'company-organization',
        permissions: [APP_PERMISSION.ORGANIZATION_VIEW]
      },
      {
        title: 'sidebar.users',
        path: 'users',
        permissions: [APP_PERMISSION.LIST_USER_VIEW]
      },
      {
        title: 'sidebar.notifications',
        path: 'notifications',
        permissions: [APP_PERMISSION.NOTIFICATION_VIEW]
      },
      {
        title: 'sidebar.admin-content',
        path: 'admin-content',
        permissions: [APP_PERMISSION.POST_VIEW, APP_PERMISSION.LINK_VIEW, APP_PERMISSION.BANNER_VIEW]
      },
      {
        title: 'sidebar.partner-apps',
        path: 'partner-apps'
      }
    ]
  }
];
