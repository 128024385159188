export function resizeArray(arr: any[], newSize: number): any[] {
  let result = new Set();
  let step = (arr.length - 1) / (newSize - 1);

  for (let i = 0; i < newSize; i++) {
    let index = Math.floor(i * step);
    while (result.has(arr[index]) && index < arr.length - 1) {
      index++;
    }
    result.add(arr[index]);
  }

  return Array.from(result);
}
