import { CommonModule } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FBLoginResponse } from '@core/models/interfaces/auth';
import { FacebookAccount } from '@core/models/interfaces/facebook/facebook-account';
import { AdAccount, IntegrationItem } from '@core/models/interfaces/facebook/integration';
import { FacebookAdsAdminService } from '@core/services/facebook.service.ts/facebook-ads-admin.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { AppState } from '@state/app.state';
import { refreshBusinessPortfolio, setFacebookAccounts, setFacebookAccountSelected } from '@state/auth/auth.actions';
import { selectFacebookAccounts, selectFacebookAccountSelected } from '@state/auth/auth.selectors';
import { selectFacebookConfig } from '@state/system/system.selectors';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';

declare let FB: any;
@Component({
  selector: 'app-integration',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TableModule,
    RadioButtonModule,
    FormsModule,
    ConfirmDialogModule,
    EmptyTableComponent,
    SkeletonModule,
    ProgressSpinnerModule,
    DialogModule,
    DropdownModule,
    MultiSelectModule
  ],
  templateUrl: './integration.component.html',
  styleUrl: './integration.component.scss',
  providers: [ConfirmationService]
})
export class IntegrationComponent implements OnInit, OnDestroy {
  readonly unsubscribe$ = new Subject<void>();
  readonly translatePrefix = 'facebook-integration.';

  isFanPageLoading: boolean = false;
  isBusinessLoading: boolean = false;
  isAdAccountLoading: boolean = false;

  isModalAdd: boolean = false;
  isConnecting: boolean = false;
  accountSelected: FacebookAccount;
  accountIdSelected: string = '';
  accounts: FacebookAccount[] = [];

  configId: string = '';

  fanPages: IntegrationItem[] = [];
  businesses: IntegrationItem[] = [];
  adAccounts: AdAccount[] = [];

  fanPagesVisibility: IntegrationItem[] = [];
  businessesVisibility: IntegrationItem[] = [];
  adAccountsVisibility: AdAccount[] = [];

  addSection: string = '';
  itemAddId: string = '';
  adAccountIds: string[] = [];
  itemRemoveId: string = '';
  addOptions: Array<{ id: string; name: string }> = [];

  constructor(
    private confirmationService: ConfirmationService,
    private store: Store<AppState>,
    private facebookAdsAdminService: FacebookAdsAdminService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.getAccounts();
    this.subscribeAccountSelected();
    this.getAppId();
  }

  getAccounts(): void {
    this.store
      .select(selectFacebookAccounts)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(accounts => {
        this.accounts = accounts;
      });
  }

  getAppId() {
    this.store
      .select(selectFacebookConfig)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.configId = data.configId;
      });
  }

  subscribeAccountSelected(): void {
    this.store
      .select(selectFacebookAccountSelected)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(account => {
        if (account) {
          this.accountSelected = account;
          this.accountIdSelected = account.id;
          this.fetchData();
        }
      });
  }

  fetchData() {
    this.fetchAllFanPages();
    this.fetchAllBusiness();
    this.fetchAllAdAccounts();

    this.fetchFanPagesVisibility();
    this.fetchBusinessVisibility();
    this.fetchAdAccountsVisibility();
  }

  fetchAllFanPages() {
    const { accessToken, id } = this.accountSelected;
    this.facebookAdsAdminService.getFanPagesVisibility(accessToken, id).subscribe({
      next: res => {
        this.fanPages = res.data;
      },
      error: () => {
        this.fanPages = [];
      }
    });
  }

  fetchFanPagesVisibility() {
    this.isFanPageLoading = true;
    const { accessToken, id } = this.accountSelected;
    this.facebookAdsAdminService.getFanPage(accessToken, id).subscribe({
      next: res => {
        this.fanPagesVisibility = res.data;
        this.isFanPageLoading = false;
      },
      error: () => {
        this.fanPagesVisibility = [];
        this.isFanPageLoading = false;
      }
    });
  }

  fetchAllBusiness() {
    const { accessToken, id } = this.accountSelected;
    this.facebookAdsAdminService.getBusinessVisibility(accessToken, id).subscribe({
      next: res => {
        this.businesses = res.data;
      },
      error: () => {
        this.businesses = [];
      }
    });
  }

  fetchBusinessVisibility() {
    this.isBusinessLoading = true;
    const { accessToken, id } = this.accountSelected;
    this.facebookAdsAdminService.getBusinessPortfolio(accessToken, id).subscribe({
      next: res => {
        this.businessesVisibility = res.data;
        this.isBusinessLoading = false;
      },
      error: () => {
        this.businessesVisibility = [];
        this.isBusinessLoading = false;
      }
    });
  }

  fetchAllAdAccounts() {
    const { accessToken, id } = this.accountSelected;
    this.facebookAdsAdminService.getAdAccountsVisibility(accessToken, id).subscribe({
      next: res => {
        this.adAccounts = res.data.flatMap(item => item.act_account);
      },
      error: () => {
        this.adAccounts = [];
      }
    });
  }

  fetchAdAccountsVisibility() {
    this.isAdAccountLoading = true;
    const { accessToken, id } = this.accountSelected;
    this.facebookAdsAdminService.getAdAccountsWithBusiness(accessToken, id).subscribe({
      next: res => {
        this.adAccountsVisibility = res.data.flatMap(item => item.act_account);
        this.adAccountIds = [...this.adAccountsVisibility].map(item => item.id);
        this.isAdAccountLoading = false;
      },
      error: () => {
        this.adAccountsVisibility = [];
        this.isAdAccountLoading = false;
      }
    });
  }

  onSelectAccount(facebookAccount: FacebookAccount) {
    this.store.dispatch(setFacebookAccountSelected({ facebookAccount }));
    localStorage.setItem('fbAccount', facebookAccount.id);
  }

  onConnect() {
    FB.login(
      (response: FBLoginResponse) => {
        if (response.status !== 'connected') {
          return;
        }

        this.ngZone.run(() => {
          this.isConnecting = true;
          this.facebookAdsAdminService.authentication(response.authResponse.accessToken).subscribe({
            next: account => {
              const uniqueArray: FacebookAccount[] = [...this.accounts, account].reduce(
                (acc: FacebookAccount[], obj) => {
                  if (!acc.some(item => item.id === obj.id)) {
                    acc.push(obj);
                  }
                  return acc;
                },
                []
              );
              this.store.dispatch(refreshBusinessPortfolio());
              this.store.dispatch(
                setFacebookAccounts({
                  facebookAccounts: uniqueArray
                })
              );

              this.onSelectAccount(account);
              this.fetchAllFanPages();
              this.fetchAllBusiness();
              this.fetchFanPagesVisibility();
              this.fetchBusinessVisibility();

              this.showDialogAdAccountAfterConnect();
            },
            error: () => {
              this.isConnecting = false;
              this.messageService.add({
                severity: 'error',
                detail: 'Failed to connect to Facebook'
              });
            }
          });
        });
      },

      { config_id: this.configId }
    );
  }

  showDialogAdAccountAfterConnect() {
    this.isAdAccountLoading = true;
    const { accessToken, id } = this.accountSelected;
    forkJoin({
      all: this.facebookAdsAdminService.getAdAccountsVisibility(accessToken, id),
      visibility: this.facebookAdsAdminService.getAdAccountsWithBusiness(accessToken, id)
    })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: results => {
          const { all, visibility } = results;
          this.adAccounts = all.data.flatMap(item => item.act_account);

          this.adAccountsVisibility = visibility.data.flatMap(item => item.act_account);
          this.adAccountIds = [...this.adAccountsVisibility].map(item => item.id);

          if (this.adAccountIds.length === 0) {
            this.onAdd('AD_ACCOUNTS');
          }
          this.isAdAccountLoading = false;
          this.isConnecting = false;
        },
        error: () => {
          this.isConnecting = false;
        }
      });
  }

  onDisconnectAccount(event: Event, id: string, name: string) {
    event.stopPropagation();
    this.confirmationService.confirm({
      header: this.translateService.instant(this.translatePrefix + 'disconnect-account'),
      message: this.translateService.instant(this.translatePrefix + 'message.disconnect-account', {
        name: name
      }),
      rejectLabel: this.translateService.instant('action.cancel'),
      acceptLabel: this.translateService.instant('action.disconnect'),
      acceptButtonStyleClass: 'btn-xl btn-danger',
      rejectButtonStyleClass: 'btn-xl btn-outline-secondary',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.facebookAdsAdminService
          .disconnectAccount(id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: () => {
              const accounts = this.accounts.filter(account => account.id !== id);
              this.store.dispatch(
                setFacebookAccounts({
                  facebookAccounts: accounts
                })
              );

              this.messageService.add({
                severity: 'success',
                detail: 'Disconnect account successfully'
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                detail: 'An error has occurred. Please try again later'
              });
            }
          });
      }
    });
  }

  onAdd(code: string) {
    this.isModalAdd = true;
    this.addSection = code;

    switch (code) {
      case 'FAN_PAGES': {
        this.addOptions = [...this.fanPages].filter(
          fp => !this.fanPagesVisibility.map(item => item.id).includes(fp.id)
        );
        break;
      }
      case 'BUSINESS': {
        this.addOptions = [...this.businesses].filter(
          bs => !this.businessesVisibility.map(item => item.id).includes(bs.id)
        );
        break;
      }
    }
  }

  onRemove(id: string, code: string) {
    this.itemRemoveId = id;
    this.addSection = code;

    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: `Remove a ${this.addHeader}`,
      rejectLabel: this.translateService.instant('action.cancel'),
      acceptLabel: this.translateService.instant('action.delete'),
      acceptButtonStyleClass: 'btn-xl btn-danger',
      rejectButtonStyleClass: 'btn-xl btn-outline-secondary',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        let source: Observable<Object>;
        switch (this.addSection) {
          case 'FAN_PAGES': {
            source = this.facebookAdsAdminService.hideAndShowFanPage(this.itemRemoveId, this.accountIdSelected, false);
            break;
          }
          case 'BUSINESS': {
            source = this.facebookAdsAdminService.hideAndShowBusiness(this.itemRemoveId, this.accountIdSelected, false);
            break;
          }
          default: {
            source = this.facebookAdsAdminService.hideAndShowAdAccount(
              this.itemRemoveId,
              this.accountIdSelected,
              false
            );
          }
        }

        source.subscribe({
          next: () => {
            this.isModalAdd = false;
            this.messageService.add({
              severity: 'success',
              detail: `Remove ${this.addHeader} successfully`
            });
            this.actionAfterEdit();
          },
          error: err => {
            this.messageService.add({
              severity: 'error',
              detail: err?.error?.error
            });
          }
        });
      }
    });
  }

  get addHeader() {
    switch (this.addSection) {
      case 'FAN_PAGES': {
        return 'Fan page';
      }
      case 'BUSINESS': {
        return 'Business';
      }
      case 'AD_ACCOUNTS': {
        return 'Ad accounts';
      }
      default: {
        return '';
      }
    }
  }

  onCancelAdd() {
    this.isModalAdd = false;
    this.itemAddId = '';
  }

  onConfirmAdd() {
    let source: Observable<Object>;
    switch (this.addSection) {
      case 'FAN_PAGES': {
        source = this.facebookAdsAdminService.hideAndShowFanPage(this.itemAddId, this.accountIdSelected, true);
        break;
      }
      case 'BUSINESS': {
        source = this.facebookAdsAdminService.hideAndShowBusiness(this.itemAddId, this.accountIdSelected, true);
        break;
      }
      default: {
        source = this.facebookAdsAdminService.setUpAdAccounts(this.adAccountIds, this.accountIdSelected);
      }
    }

    source.subscribe({
      next: () => {
        this.isModalAdd = false;
        this.messageService.add({
          severity: 'success',
          detail: `Add ${this.addHeader} successfully`
        });
        this.actionAfterEdit();
      },
      error: err => {
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error
        });
      }
    });
  }

  actionAfterEdit() {
    this.itemAddId = '';
    this.itemRemoveId = '';
    switch (this.addSection) {
      case 'FAN_PAGES': {
        this.fetchFanPagesVisibility();
        break;
      }
      case 'BUSINESS': {
        this.fetchBusinessVisibility();
        this.store.dispatch(refreshBusinessPortfolio());
        break;
      }
      default: {
        this.fetchAdAccountsVisibility();
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
