<div class="bg-white px-8 pb-5">
  <!-- HEADER -->
  <header class="h-15 bg-white flex items-center justify-between">
    <h1 class="text-6 font-semibold">{{ 'dashboard.dashboard' | translate }}</h1>

    <div class="flex items-center gap-4">
      <div class="flex items-center gap-1.5">
        <app-custom-calendar
          [maxDate]="currentDate"
          [filterTypeInit]="DateFilterType.THIS_MONTH"
          [(datesSelected)]="datesSelected"
          (datesSelectedChange)="datesSelectedChange()"
          appendTo="body"></app-custom-calendar>

        @if (accountBusinesses.length) {
          <app-ad-account-select
            [selectType]="'MULTIPLE'"
            [(adAccountsSelected)]="adAccountsSelected"
            [accountBusinesses]="accountBusinesses"
            (adAccountsSelectedChange)="onChangeAdAccount()"></app-ad-account-select>
        }
      </div>

      <app-export-report-dashboard
        [accessToken]="facebookAccount.accessToken"
        [adAccountId]="adAccountsSelected?.[0]?.id"
        [datesSelected]="datesSelected"></app-export-report-dashboard>
    </div>
  </header>

  <div class="h-[calc(100vh-132px)] overflow-y-auto">
    <!-- REACH & ENGAGEMENT PERFORMANCE -->
    <app-reach-engagement-performance
      [isLoading]="reachEngagementLoading"
      [reachEngagementStatistic]="reachEngagementStatistic"
      [reachEngagementData]="reachEngagementData"
      [datesSelected]="datesSelected"></app-reach-engagement-performance>

    <!-- PERFORMANCE OVERVIEW -->
    <app-performance-overview
      [isLoading]="performanceLoading"
      [performanceData]="performanceData"
      [staticalPerformanceData]="staticalPerformanceData"
      [datesSelected]="datesSelected"></app-performance-overview>

    <!-- AUDIENCE AND INSIGHTS -->
    <app-audience-and-insights
      [isAgeAndGenderLoading]="isAgeAndGenderLoading"
      [isDayAndTimeLoading]="isDayAndTimeLoading"
      [isTopStatesLoading]="isTopStatesLoading"
      [isPlacementLoading]="isPlacementLoading"
      (dataChange)="fetchChartData($event)"
      [ageAndGenderData]="ageAndGenderData"
      [dayAndTimeData]="dayAndTimeData"
      [placementData]="placementData"
      [topStatesData]="topStatesData"></app-audience-and-insights>
  </div>
</div>
